import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import { useSearchParam } from 'react-use'
import PlantBasicInfo from '../comps/molecules/PlantBasicInfo'
import MovingComponentsScreen from 'comps/molecules/MovingComponentsScreen'
import ABStore from 'store/ABStore'

const testPlantList = [
  'mool',
  // 'ramen',
  'coffee_mega',
  'coffee_compose',
  'onion',
  'potato',
  'tomato',
  // 'mychew',
  'coffee_starbucks',
  'apple',
  'sweetpotato',
  'egg',
  // 'pokachip',
  'orange',
  'pineapple',
  'greenOnion',
  'banana',
  'grapefruit',
  'grape',
  'avocado',
  'lemon',
  'galic',
  'rice',
  'carrot',
  'toothpaste',
  'dishdetergent',
  'toothset',
]

const SelectFarm = () => {
  const token = useSearchParam('token')
  const shareType = useSearchParam('shareType')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const phoneNumber = useSearchParam('phoneNumber')
  const indexNumber = useSearchParam('indexNumber')
  const [selectItem, setSelectItem] = useState(null)
  const [noPhoneNumber, setNoPhoneNumber] = useState(true)
  const [myItemName, setMyItemName] = useState('')
  const [plantList, setPlantList] = useState([
    'mool',
    'onion',
    'potato',
    'coffee_starbucks',
    'apple',
    'sweetpotato',
    'tomato',
    'egg',
    'orange',
    'pineapple',
    'greenOnion',
    'banana',
    'grapefruit',
    'avocado',
    'lemon',
    'galic',
    'rice',
    'carrot',
    'grape',
  ])

  const [userId, setUserId] = useState()
  const [clearedUserList, setClearedUserList] = useState([])
  const [scrollIndicatorOn, setScrollIndicatorOn] = useState(true)

  const getUserId = async () => {
    if (!userId) {
      const result = await backendApis.getUserId(token)
      if (result.status === 200 && result.data) {
        setUserId(result.data)
      }
    }
  }

  const getUserBasicInfo = async () => {
    const result = await backendApis.getUserBasicInfo(token)
    if (result?.phoneNumber !== '') {
      setNoPhoneNumber(false)
    }
  }

  const fetchClearedUsers = async () => {
    const result = await backendApis.fetchClearedUsers()
    setClearedUserList(result?.data)
  }

  useEffect(() => {
    getUserId()
    getUserBasicInfo()
    fetchClearedUsers()
  }, [])

  useEffect(() => {
    if (userId) {
      ABStore.initialize({ userId: userId })
    }
    if (ABStore?.gifticonTester) {
      setPlantList([...testPlantList])
    }
  }, [userId, ABStore?.gifticonTester])

  const CTA = () => {
    return (
      <div className='mt-[1vw] border-none w-full'>
        {noPhoneNumber ? (
          <button className='w-full flex justify-center items-center'>
            <div
              className={`flex justify-center items-center text-black bg-gradient-to-r from-[#FFF0BB] to-[#FF7A00] rounded-[99vw] w-[80%] p-[4vw] text-[4.5vw] font-maplestory`}
              style={{
                opacity: selectItem === null ? 0.5 : 1.0,
              }}
              onClick={() => {
                window.location.href = '#closeGame'
              }}
            >
              회원가입 후 이용 할 수 있어요
            </div>
          </button>
        ) : (
          <div className='w-full justify-center flex items-center flex-col'>
            {selectItem !== null && (
              <button
                className='flex mb-[1vw] text-[4vw]'
                onClick={() => {
                  const prop = {
                    screen: 'TermsofService',
                  }
                  window.location.href = `#navigate.${JSON.stringify(prop)}`
                }}
              >
                올웨이즈
                <div
                  className='pl-[4px] underline'
                  style={{ paddingLeft: 4, textDecoration: 'underline' }}
                >
                  이용약관
                </div>
                에 동의하고
              </button>
            )}
            <button
              className='flex justify-center items-center bg-gradient-to-b from-[#FFF0BB] to-[#FF7A00] rounded-[99vw] w-[80%] p-[4vw] text-[5.5vw] text-black font-maplestory relative font-bold'
              style={{
                boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
                opacity: selectItem === null ? 0.5 : 1.0,
              }}
              onClick={() => {
                if (selectItem === null) return
                window.location.href = `/farmPage?token=${token}&itemType=${
                  plantList[selectItem]
                }&shareType=${shareType}&alwayzInvitorId=${alwayzInvitorId}&myItemName=${myItemName}${
                  phoneNumber ? `&phoneNumber=${phoneNumber}` : ''
                }${indexNumber ? `&indexNumber=${indexNumber}` : ''}`
              }}
            >
              {selectItem !== null ? (
                <>
                  <>재배 시작하기</>
                  <img
                    className='absolute slide-tl w-[15vw] z-[222] right-[0vw] top-[6vw]'
                    src='/icon/finger.png'
                    alt=''
                  />
                </>
              ) : (
                <>작물을 골라보세요</>
              )}
            </button>
          </div>
        )}
      </div>
    )
  }

  const Plant = ({ selectedItem = 0, itemType = 'onion' }) => {
    return (
      <div
        style={{ ...styles.gridItemStyle, ...styles.scrollableContainer }}
        onClick={() => {
          setSelectItem(selectedItem)
        }}
      >
        <div>
          {itemType === 'coffee_starbucks' && (
            <img
              style={{
                position: 'absolute',
                width: '9vw',
                right: '6vw',
                top: '2vw',
              }}
              src={`/icon/starbucks.png`}
              alt=''
            />
          )}
          {itemType === 'coffee_compose' && (
            <img
              style={{
                position: 'absolute',
                width: '9vw',
                right: '6vw',
                top: '2vw',
                borderRadius: 99,
              }}
              src={`/icon/compose.png`}
              alt=''
            />
          )}
          {itemType === 'coffee_mega' && (
            <img
              style={{
                position: 'absolute',
                width: '9vw',
                right: '6vw',
                top: '2vw',
              }}
              src={`/icon/mega.png`}
              alt=''
            />
          )}

          {[
            'coffee_mega',
            'coffee_compose',
            'ramen',
            'pokachip',
            'mychew',
          ]?.includes(itemType) && (
            <img
              style={{
                position: 'absolute',
                width: '18vw',
                left: '10vw',
                top: '0vw',
              }}
              src={`/icon/easyTag.png`}
              alt=''
            />
          )}

          <img
            style={{
              width: '30vw',
              marginBottom: '2vw',
            }}
            src={`/icon/farm/${itemType}.png`}
            alt=''
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {selectedItem === selectItem ? (
            <div
              style={{
                width: 20,
                height: 20,
                border: '2px solid #69E299',
                borderRadius: 99,
                backgroundImage: `url(/icon/selectFarmCheck.png)`,
              }}
            ></div>
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
                border: '2px solid #69E299',
                borderRadius: 99,
              }}
            ></div>
          )}

          <div style={{ marginLeft: 8 }}>
            {PlantBasicInfo.PlantNameAndAmount(itemType).koreanName}
          </div>
        </div>
      </div>
    )
  }

  if (!userId) {
    return <></>
  } else
    return (
      <div className=' flex flex-col justify-center items-center bg-black bg-opacity-50 overflow-x-hidden pt-[4vw]'>
        {/* 타이틀 */}
        <div
          className='text-[#fff] flex justify-center items-end mb-[1vw]'
          style={{
            fontFamily: 'maplestory',
          }}
        >
          <div className='mt-[4vw] text-[6vw]'>
            무료로 받을 작물을 골라보세요
          </div>
        </div>

        {/* 선택 */}
        <div className='w-full flex h-[70vh]'>
          <div className='w-full h-full bg-black bg-opacity-70 rounded-2xl flex flex-col items-center justify-center p-[2vw] pt-[2vw]'>
            {/* select section */}
            <div
              className='flex-1 relative flex flex-wrap overflow-y-scroll scrollbar-hide '
              onPointerDown={() => {
                setScrollIndicatorOn(false)
              }}
            >
              {plantList.map((item, index) => (
                <Plant selectedItem={index} itemType={item} key={index} />
              ))}
              {scrollIndicatorOn && (
                <div className='w-full h-full absolute flex justify-center items-center flex-col pt-[20vw] font-bold text-[6vw] text-center'>
                  <img
                    className='scroll-indicator w-[15vw] z-[222] right-[40%] top-[40%]'
                    src='/icon/finger.png'
                    alt=''
                  />
                  <div className='p-[20px] rounded-[16px] bg-[#f5f5f5] text-[#2F443D]'>
                    무료로 배송받을 작물을 <br />
                    골라보세요
                  </div>
                </div>
              )}
            </div>
            {/* detail section */}
            <div className='w-full mt-[8px] bg-[#2F443D] rounded-[8px] p-[2vw] text-center text-[4vw]'>
              {selectItem !== null
                ? `다 키우면 ${
                    PlantBasicInfo.PlantNameAndAmount(plantList[selectItem])
                      .koreanName
                  } ${
                    PlantBasicInfo.PlantNameAndAmount(plantList[selectItem])
                      .amount
                  }${
                    PlantBasicInfo.PlantNameAndAmount(plantList[selectItem])
                      .postfix
                  } 보내드려요`
                : '다 키워서 수확하면 무료로 보내드려요'}
            </div>
            {/* CTA section */}
          </div>
        </div>

        {/* CTA */}
        <CTA />

        {/* 보증 */}
        <div className='flex text-[3.5vw]'>
          <div
            className='w-[5vw] mr-[4px]'
            style={{
              backgroundImage: `url(/icon/selectFarmShield.png)`,
            }}
          />
          <div className='text-[#D3526A] font-bold'>올웨이즈</div>가 보장하는
          무료 수확
        </div>

        {/* user section */}
        <div className='w-full flex justify-end items-center flex-col mb-[8px]'>
          <MovingComponentsScreen
            userList={clearedUserList?.slice(0, 10) ?? []}
          />
          <MovingComponentsScreen
            userList={clearedUserList?.slice(10, 20) ?? []}
          />
          <MovingComponentsScreen
            userList={clearedUserList?.slice(20, 30) ?? []}
          />
          {/* <MovingComponentsScreen userList={clearedUserList.splice(0, 5)} /> */}
        </div>
      </div>
    )
}

const styles = {
  gridContainerStyle: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    // maxHeight: "50%",
    overflowY: 'auto',
  },
  gridItemStyle: {
    position: 'relative',
    flex: '0 0 calc(50% - 10px)',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  scrollableContainer: {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #eee',
  },
  scrollableContent: {
    paddingRight: '16px', // Adjust as needed to account for scrollbar width
    paddingBottom: '16px', // Adjust as needed to account for scrollbar height
  },
}

export default SelectFarm
