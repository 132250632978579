import backendApis from 'utils/backendApis'

// 수확 완료에 대한 검증
export const farmFinish = async (token) => {
  const result = await backendApis.finishedFarm(token, 'PUT', {})

  if (result?.status === 200) {
    return true
  }

  throw new Error()
}

export const harvestComplete = async ({ token, itemType }) => {
  const result = await backendApis.harvestComplete(token, itemType)

  if (result?.status === 200) {
    return true
  }

  throw new Error()
}
