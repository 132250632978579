import { observable } from 'mobx'

const AuthStore = observable({
  token: '',
  key: '',
  minCodePushVersion: '6.5.18',

  setToken(data) {
    this.token = data
  },
  setKey(data) {
    this.key = data
  },
})

export default AuthStore
