import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import WhiteTextTimer from '../WhiteTextTimer'
import Log from '../../utils/log'
import commaNumber from 'comma-number'
import BottomSheetStore from '../../store/BottomSheetStore'
import ModalStore from '../../store/ModalStore'
import AB from '../../utils/ab'
import AlwayzUserStore from '../../store/AlwayzUserStore'
import TimeStore from '../../store/TimeStore'
import UserStore from '../../store/UserStore'
import DealAutomationStore from '../../store/DealAutomationStore'
import { isPurchaseUser } from 'utils/utils'
import timeChecker from 'utils/timeChecker'
import ToastStore from 'store/ToastStore'
import moment from 'moment'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import BottomSheetPurchaseQuest from 'comps/molecules/BottomSheetPurchaseQuest'
import ABv2 from 'utils/abv2'
import BottomSheetHeader from 'comps/molecules/BottomSheetHeader'
import ABStore from 'store/ABStore'
import ImageText from 'utils/imageText'
import ABv3 from 'utils/ab_v3'
import WinnerDealButton from './WinnerDealButton'
import AlfarmAdStore from 'store/AlfarmAdStore'
import { purchaseHistoryThisWeek } from 'utils/utils'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const day = moment().date()

const ImageSprite = () => {
  if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.firstBrandDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.firstBrandDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.firstBrandDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.firstBrandDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.firstBrandDealData.purchaseScreenText,
    }
  } else if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.secondBrandDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.secondBrandDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.secondBrandDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.secondBrandDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.secondBrandDealData.purchaseScreenText,
    }
  }
}

function convertCodePushVer(x) {
  if (!x || !x?.includes('.')) {
    return 0
  }
  return (
    Number(x.split('.')[0] * 1000) +
    Number(x.split('.')[1] * 100) +
    Number(x.split('.')[2])
  )
}

const GetWaterButtonBs = observer(
  ({
    farmData,
    setFarmData,
    token,
    setShowPopup,
    setResourceType,
    setAddWater,
    giveWaterCount,
    userId,
    dailyWaterPurchaseRewardToggle,
    newbiePurchaseWaterRemainTime,
    couponInfo,
    isSecretIconOn,
    secretScratchHistory,
    setGganbuData,
    showSellerCouponPurchaseIconOn,
    selectedSellerCoupon,
    codePushVersionAvailable,
    triggerMissionHunter,
    setCanClickGiveWater,
    setPhotoReviewItemsInfo,
    photoReviewItemsInfo,
    setInviteResultStatus,
    setCanClickSellerFarm,
    userCodePushVersion,
    altoonData,
    setAltoonData,
    dramaData,
    setDramaData,
  }) => {
    const [
      waterBottomSheetInfoTimeChecker,
      setWaterBottomSheetInfoTimeChecker,
    ] = useState()
    const [isScrollOn, setIsScrollOn] = useState(true)
    const [firstBrandDealOngoing, setFirstBrandDealOngoing] = useState(false)
    const [secondBrandDealOngoing, setSecondBrandDealOngoing] = useState(false)
    const [canClickButton, setCanClickButton] = useState(true)
    const [completeTimeDealQuest, setCompleteTimeDealQuest] = useState(
      new Date(),
    )
    const [lastTimeDealChecked, setLastTimeDealChecked] = useState()
    const [browsingMissionClearedAt, setBrowsingMissionClearedAt] = useState()
    const [searchMissionClearedAt, setSearchMissionClearedAt] = useState()
    const [timeDealQuestCount, setTimeDealQuestCount] = useState(0)

    const [weeklyPurchaseCount, setWeeklyPurchaseCount] = useState()
    const [canSolveQuiz, setCanSolveQuiz] = useState(false)
    const [
      isCherryPickPurchaseRewardWaterButtonPressed,
      setIsCherryPickPurchaseRewardWaterButtonPressed,
    ] = useState(false)

    const [
      lastClearedWeeklyPurchaseRewardTime,
      setLastClearedWeeklyPurchaseRewardTime,
    ] = useState(0)
    const [gganbuLength, setGganbuLength] = useState(0)
    const [clearedInviteGganbuFirst, setClearedInviteGganbuFirst] =
      useState(false)
    const [clearedInviteGganbuSecond, setClearedInviteGganbuSecond] =
      useState(false)
    const [clearedInviteGganbuThird, setClearedInviteGganbuThird] =
      useState(false)

    const [level, setLevel] = useState('')
    const [canClickGganbuButton, setCanClickGganbuButton] = useState('false')
    const [checkedReviewModal, setCheckedReviewModal] = useState(false)
    const [purchasedCheckInRewardCount, setPurchasedCheckInRewardCount] =
      useState(5)
    const [clearedReviewQuest, setClearedReviewQuest] = useState(true)
    const [clearedPurchasedCheckInReward, setClearedPurchasedCheckInReward] =
      useState(true)
    const [solvedQuizCount, setSolvedQuizCount] = useState(true)

    const [isCherryPickingUser, setIsCherryPickingUser] = useState(false)

    const [showInfoIcon, setShowInfoIcon] = useState(false)
    const [infoContent, setInfoContent] = useState()
    const [clearedPurchaseRewardCount, setClearedPurchaseRewardCount] =
      useState(0)

    const [clearedAltoonMissionOne, setClearedAltoonMissionOne] = useState(
      altoonData?.missionOneClearedAt,
    )
    const [clearedAltoonMissionTwo, setClearedAltoonMissionTwo] = useState(
      altoonData?.missionTwoClearedAt,
    )
    const [clearedAltoonMissionFive, setClearedAltoonMissionFive] = useState(
      altoonData?.missionFiveClearedAt,
    )

    const [clearedDramaMissionOne, setClearedDramaMissionOne] = useState(
      dramaData?.missionOneClearedAt,
    )

    const [isNewbiePurchaseButtonClicked, setIsNewbiePurchaseButtonClicked] =
      useState(false)
    const [isDailyInviteButtonClicked, setIsDailyInviteButtonClicked] =
      useState(false)
    const [isSecretScratchButtonClicked, setIsSecretScratchButtonClicked] =
      useState(false)
    const [isCouponUsageNudgeClicked, setIsCouponUsageNudgeClicked] =
      useState(false)
    const [isSellerCouponNudgeClicked, setIsSellerCouponNudgeClicked] =
      useState(false)

    const [isSearchMissionButtonClicked, setIsSearchMissionButtonClicked] =
      useState(false)
    const [isPossibleBrowsingTimeDeal, setIsPossibleBrowsingTimeDeal] =
      useState(false)
    const [isBrowsingMissionAvailable, setIsBrowsingMissionAvailable] =
      useState(false)
    const [isSearchMissionAvailable, setIsSearchMissionAvailable] =
      useState(false)
    const [
      isSearchMissionRewardReceivable,
      setIsSearchMissionRewardReceivable,
    ] = useState(false)
    const [scrollIndicator, setScrollIndicator] = useState(false)
    const [isInviteWaterMissionAvailable, setIsInviteWaterMissionAvailable] =
      useState(false)

    const [lastTenMinDealClearedAt, setLastTenMinDealClearedAt] = useState()
    const [tenMinDealBrowsingQuestStatus, setTenMinDealBrowsingQuestStatus] =
      useState('waiting')
    const [resultOfTenMinDealValue, setResultOfTenMinDealValue] = useState([])
    const [beforeAttendanceTenMinDeal, setBeforeAttendanceTenMinDeal] =
      useState(true)
    const [canClickFightingGganbuQuest, setCanClickFightingGganbuQuest] =
      useState(false)
    const [styleSelect, setStyleSelect] = useState(true)
    const [winnerDealTimeIntervalReady, setWinnerDealTimeIntervalReady] =
      useState(false)
    const newUserQuestPrevent = giveWaterCount > 300 ? true : false

    const cheerupGganbuTest = ABv3(farmData?.userId, 'cheerupGganbuTest', [1])

    const timeFormat = moment(new Date()).format('HH')
    const cheerupGganbuTime = timeFormat >= '07' && timeFormat < '22'

    const showDramaWaterMission = AB(
      farmData?.userId,
      'showDramaFertMission0913', // 비료미션이랑 독립적으로 실험 showDramaFertMission0913
      [5, 6, 100],
    )

    // utc 플러그인 로드
    dayjs.extend(utc) // utc 플러그인 활성화

    const throttleRef = useRef({})

    useEffect(() => {
      setTimeout(() => {
        setStyleSelect(false)
      }, 650)

      const headerRef = document.querySelector('[data-rsbs-header="true"]')
      const headerStyle = {
        height: headerRef.style.height,
        marginTop: headerRef.style.marginTop,
        boxShadow: headerRef.style.boxShadow,
      }
      headerRef.style.height = '10vw'
      headerRef.style.marginTop = '-50vw'
      headerRef.style.boxShadow = 'none'

      const contentRef = document.querySelector('[data-rsbs-content="true"]')
      const contentStyle = {
        marginTop: contentRef.style.marginTop,
      }

      contentRef.style.marginTop = '46vw'

      return () => {
        headerRef.style.height = headerStyle.height
        headerRef.style.marginTop = headerStyle.marginTop
        headerRef.style.boxShadow = headerStyle.boxShadow
        contentRef.style.marginTop = contentStyle.marginTop
      }
    }, [styleSelect, BottomSheetStore?.isBottomSheetOpen])

    useEffect(() => {
      let observer = new IntersectionObserver((e) => {
        if (e?.[0].isIntersecting) {
          setScrollIndicator(false)
        } else {
          setScrollIndicator(true)
        }
      })
      let div = document.querySelector('#waterBottomSheetEnd')
      observer?.observe(div)

      if (
        giveWaterCount === 4 &&
        !farmData?.questLogForGetWaterDailyAttendance
      ) {
        setIsScrollOn(false)
      }
    }, [])

    useEffect(() => {
      if (
        TimeStore.currentDayJS >
          dayjs(DealAutomationStore.firstBrandDealData.timeRangeStart) &&
        TimeStore.currentDayJS <
          dayjs(DealAutomationStore.firstBrandDealData.timeRangeEnd) &&
        giveWaterCount > 150
      ) {
        setFirstBrandDealOngoing(true)
      } else if (
        TimeStore.currentDayJS >
          dayjs(DealAutomationStore.secondBrandDealData.timeRangeStart) &&
        TimeStore.currentDayJS <
          dayjs(DealAutomationStore.secondBrandDealData.timeRangeEnd) &&
        giveWaterCount > 150
      ) {
        setSecondBrandDealOngoing(true)
      }
    }, [])

    useEffect(() => {
      const isBrowsingOpen =
        // waterBottomSheetInfoTimeChecker?.tenMinDealBrowsingQuestAvailable &&
        DealAutomationStore?.tenMinDealBrowsingQuestOpen()
      const isBrowsingAvailableTime =
        DealAutomationStore?.isTenMinDealBrowsingQuestAvailable() // 실제 브라우징 가능 시간
      const isBeforeCloseTime = checkTenMinDealCloseTime()
      const isCompleted = tenMinDealBrowsingCompletedChecker(
        lastTenMinDealClearedAt,
      )
      const determineBrowsingQuestStatus = () => {
        if (!isBrowsingOpen) return 'notOpen'
        if (isBrowsingAvailableTime && isCompleted) return 'done'
        if (isBrowsingAvailableTime) return 'available'
        return 'waiting'
      }
      setTenMinDealBrowsingQuestStatus(determineBrowsingQuestStatus())

      //<TenMinDealBrowsingButton/>
    }, [resultOfTenMinDealValue])

    useEffect(() => {
      if (!farmData?.solvedQuizHistory) {
        setCanSolveQuiz(true)
      }
      if (farmData?.solvedQuizHistory) {
        if (
          TimeStore.currentDayJS -
            dayjs(
              farmData?.solvedQuizHistory?.[
                farmData?.solvedQuizHistory?.length - 1
              ]?.solvedAt,
            ) >
          1000 * 60 * 60 * AlfarmAdStore.quizAdTimeIntervalBetweenExposure
        ) {
          setCanSolveQuiz(true)
        }
      }
    }, [])

    useEffect(() => {
      setPurchasedCheckInRewardCount(
        farmData?.purchasedCheckInReward?.rewardHistory?.length,
      )
    }, [farmData])

    useEffect(() => {
      if (BottomSheetStore.isBottomSheetOpen === 'water') {
        getGganbuList()
        isCherryPickUser()
        getWaterBottomSheetInfo()
        getDailyPurchaseHistoryForAlfarm()
        getPhotoReviewItemsInfo()
        // getWinnerDealAdItemInfos()
      }
    }, [BottomSheetStore.isBottomSheetOpen])

    useEffect(() => {
      if (
        (TimeStore.currentDayJS - lastTimeDealChecked > 3600000 &&
          timeDealQuestCount < 2) ||
        waterBottomSheetInfoTimeChecker?.timeDealBrowsingMissionAvailable
      ) {
        setIsPossibleBrowsingTimeDeal(true)
      } else if (
        TimeStore.currentDayJS - lastTimeDealChecked < 3600000 &&
        timeDealQuestCount < 2 &&
        !waterBottomSheetInfoTimeChecker?.timeDealBrowsingMissionAvailable
      ) {
        setIsPossibleBrowsingTimeDeal(false)
      } else if (timeDealQuestCount >= 2) {
        setIsPossibleBrowsingTimeDeal(false)
      }
    }, [lastTimeDealChecked])

    const shareKakao = () => {
      const inviteData = {
        shareType: 'AlfarmGganbuInvite',
        // referralCode: farmData?.referralCode,
        title: '[올팜] 우리 맞팜해요!',
        description: '서로의 농장에서 물과 비료를 받을 수 있어요.',
        bannerImage:
          'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    const closeBottomSheet = () => {
      setCanClickGiveWater(false)
      setCanClickSellerFarm(false)
      BottomSheetStore.setIsbottomSheetOpen('basic')
      setTimeout(() => {
        setCanClickGiveWater(true)
        setCanClickSellerFarm(true)
      }, 1500)
    }

    const checkedPhoneBookGganbu = async () => {
      backendApis.giveReward(token, 'PUT', {
        loggingRewardName: 'checkedPhoneBookGganbu',
        rewardType: 'water',
        valueAmount: 5,
      })
      backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'checkedPhoneBookGganbu',
      })
    }

    // const getWinnerDealAdItemInfos = async () => {
    //   const result = await backendApis.getWinnerDealAdsInfo()
    //   if (result?.data?.mainAdData) {
    //     setWinnerDealMainAdData(result?.data?.mainAdData)
    //     setWinnerDealSubAdData(result?.data?.subAdData)
    //   }
    //   setClearedWinnerDealAd(result?.data?.cleared)
    // }

    const tenMinDealBrowsingCompletedChecker = (lastClearedTime) => {
      const compareTimeMoment = moment(lastClearedTime)

      if (!compareTimeMoment?.isValid()) {
        return true
      }
      const now = moment()
      if (!compareTimeMoment?.isSame(now, 'day')) {
        return false // 다른 날이면 브라우징 퀘스트 가능
      }
      if (now?.diff(lastClearedTime, 'minute') < 10) {
        return true
      }
      return false
    }

    const checkTenMinDealCloseTime = () => {
      const now = dayjs()
      const isAM = now.hour() < 12
      const isBeforeCloseTimeAM = now?.isBefore(
        now.startOf('day').add(8, 'hour').add(11, 'minute'),
      )
      const isBeforeCloseTimePM = now?.isBefore(
        now.startOf('day').add(20, 'hour').add(11, 'minute'),
      )
      if (isAM) {
        return isBeforeCloseTimeAM
      } else {
        return isBeforeCloseTimePM
      }
    }

    const shareLink = () => {
      const inviteData = {
        shareType: 'AlfarmWaterInvite',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    const getClearedPurchaseRewardCount = async (data) => {
      let clearedPurchaseRewardCount
      if (data?.dailyWaterPurchaseReward === undefined) {
        clearedPurchaseRewardCount = 0
      } else if (
        data?.waterBottomSheetInfoTimeChecker
          ?.purchaseWaterBottomSheetAvailable === false
      ) {
        clearedPurchaseRewardCount = data?.dailyWaterPurchaseReward?.length
      } else {
        clearedPurchaseRewardCount = 0
      }
      setClearedPurchaseRewardCount(clearedPurchaseRewardCount)
    }

    const getWaterBottomSheetInfo = async () => {
      // 여기입니다~ 바텀싯 정보를 불러옴. 퀘스트 수행 가능 여부를 체크하기 위함

      const result = await backendApis.getWaterBottomSheetInfo(token, 'GET', {
        isFrom: 'bottomSheet',
      })

      let tempFarmData = { ...farmData }
      tempFarmData.questLogForGetWaterDailyAttendance =
        result?.questLogForGetWaterDailyAttendance
      tempFarmData.fertilizerSet = result?.fertilizerSet
      tempFarmData.isCherryPickUser = result?.isCherryPickUser
      tempFarmData.water = result?.water
      tempFarmData.questLogForCheckLiveReview =
        result?.questLogForCheckLiveReview

      let lastClearedWeeklyPurchaseRewardTime

      if (!result?.weeklyPurchaseRewardFertClear) {
        lastClearedWeeklyPurchaseRewardTime = new Date(
          '1970-01-01T06:01:39.853+00:00',
        )
      } else {
        lastClearedWeeklyPurchaseRewardTime =
          result?.weeklyPurchaseRewardFertClear
      }

      setLastTenMinDealClearedAt(
        result?.tenMinDealBrowsingQuest
          ? dayjs(
              // 여기는 dayjs ?
              result?.tenMinDealBrowsingQuest[
                result?.tenMinDealBrowsingQuest?.length - 1
              ]?.clearedAt,
            )
          : dayjs('2022-01-01'),
      )

      setResultOfTenMinDealValue(result?.tenMinDealBrowsingQuest)

      setLastClearedWeeklyPurchaseRewardTime(
        lastClearedWeeklyPurchaseRewardTime,
      )

      setSolvedQuizCount(
        result?.waterBottomSheetInfoTimeChecker?.solvedQuizCount?.length,
      )

      tempFarmData.questLogFroTimeDealCheckedAt =
        result?.questLogFroTimeDealCheckedAt
      tempFarmData.questLogFroTimeDealCheckedAt =
        result?.questLogFroTimeDealCheckedAt
      tempFarmData.checkedPhoneBookGganbu = result?.checkedPhoneBookGganbu
      setWaterBottomSheetInfoTimeChecker(
        result?.waterBottomSheetInfoTimeChecker,
      )
      setFarmData(tempFarmData)
      setBrowsingMissionClearedAt(
        result?.browsingMissionClearedAt
          ? dayjs(result?.browsingMissionClearedAt)
          : dayjs('2022-01-01'),
      )
      setSearchMissionClearedAt(
        result?.searchMissionClearedAt
          ? dayjs(result?.searchMissionClearedAt)
          : dayjs('2022-01-01'),
      )
      setIsBrowsingMissionAvailable(
        TimeStore.currentDayJS -
          dayjs(result?.browsingMissionClearedAt ?? '2022-01-01') >
          14400000,
      )
      setIsSearchMissionAvailable(
        TimeStore.currentDayJS -
          dayjs(result?.searchMissionClearedAt ?? '2022-01-01') >
          14400000,
      )

      setIsSearchMissionRewardReceivable(
        dayjs(result?.searchMissionClearedAt ?? '2022-01-01') >
          dayjs(result?.searchMissionRewardReceivedAt ?? '2022-01-01'),
      )
      getClearedPurchaseRewardCount(result)
      setClearedReviewQuest(
        !result?.waterBottomSheetInfoTimeChecker?.liveReviewAvailable,
      )

      getTimeDealQuestCount(
        result,
        result?.waterBottomSheetInfoTimeChecker?.standardHour,
      )

      setClearedPurchasedCheckInReward(
        !result?.waterBottomSheetInfoTimeChecker
          ?.purchasedCheckInRewardAvailable,
      )

      setBeforeAttendanceTenMinDeal(
        result?.waterBottomSheetInfoTimeChecker
          ?.tenMinDealAttendanceWaterBottomSheetAvailable,
      )

      setCanClickFightingGganbuQuest(
        result?.waterBottomSheetInfoTimeChecker?.cheerupGganbuAvailable,
      )

      let logInLogsByTime

      if (!result?.questLogFroTimeDealCheckedAt) {
        logInLogsByTime = new Date('1970-01-01T06:01:39.853+00:00')
        setCompleteTimeDealQuest(logInLogsByTime)
      } else {
        logInLogsByTime = new Date(
          result?.questLogFroTimeDealCheckedAt[
            result?.questLogFroTimeDealCheckedAt?.length - 1
          ],
        )

        setCompleteTimeDealQuest(logInLogsByTime)
        if (TimeStore.currentDayJS - dayjs(logInLogsByTime) > 3600000) {
        }
      }
      let logInLogsByTimeForLastTimeDealChecked
      if (!result?.questLogFroTimeDealCheckedAt) {
        logInLogsByTimeForLastTimeDealChecked = dayjs(
          '1970-01-01T06:01:39.853+00:00',
        )
        setLastTimeDealChecked(logInLogsByTimeForLastTimeDealChecked)
      } else {
        logInLogsByTimeForLastTimeDealChecked = dayjs(
          result?.questLogFroTimeDealCheckedAt?.[
            result?.questLogFroTimeDealCheckedAt.length - 1
          ],
        )
        setLastTimeDealChecked(logInLogsByTimeForLastTimeDealChecked)
      }
      if (
        result?.waterBottomSheetInfoTimeChecker?.dailyWaterInvite ||
        result?.dailyWaterInvite?.length < 5
      ) {
        setIsInviteWaterMissionAvailable(true)
      }

      const winnerDealReady = result?.waterBottomSheetInfoTimeChecker
        ?.lastWinnerDealClearedAt
        ? new Date(
            result?.waterBottomSheetInfoTimeChecker?.lastWinnerDealClearedAt,
          ).getTime() +
            1000 *
              60 *
              60 *
              AlfarmAdStore.winnerDealTimeIntervalBetweenExposure <
          new Date().getTime()
        : true
      setWinnerDealTimeIntervalReady(winnerDealReady)
    }

    const getPhotoReviewItemsInfo = async () => {
      const result = await backendApis.getPhotoReviewItemsInfo(token)
      if (result?.status === 200) {
        setPhotoReviewItemsInfo(result?.data)
      }
    }

    const searchMissionButtonClicked = async () => {
      const purchaseData = {
        enteringComponent: 'Alfarm_DailyPurchaseWaterRewardScreen',
        text: '올팜',
        source:
          clearedPurchaseRewardCount < 2
            ? 'https://assets.ilevit.com/1b6ec647-f56b-4c25-8cda-daec68e79d97.png'
            : 'https://assets.ilevit.com/f86d8803-3344-495f-b03b-602ff91717db.png',
        rewardText: '상품을 검색하고 물 10g을 받아보세요',
        placeholderType: 'alfarmSearchMission',
        isAlfarmSearchMission: true,
        isFrom: 'bottomSheetSearchMission',
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
    }

    const getTimeDealQuestCount = (data, standardHour) => {
      let logInLogsByTime

      if (!data?.questLogFroTimeDealCheckedAt) {
        logInLogsByTime = []
      } else {
        logInLogsByTime = data?.questLogFroTimeDealCheckedAt.filter((x) => {
          let availableTime
          const currentTime = dayjs().startOf('h')
          const currentHour = Number(currentTime.format('HH'))
          if (currentHour < standardHour) {
            availableTime = dayjs()
              .startOf('d')
              .subtract(1, 'd')
              .add(standardHour, 'h')
          } else {
            availableTime = dayjs().startOf('d').add(standardHour, 'h')
          }
          let isAvailable = false
          if (availableTime?.diff(dayjs(x), 'ms') > 0) {
            isAvailable = true
          }
          return !isAvailable
        })
      }

      const logInLogsByTimeSet = new Set(logInLogsByTime).size
      setTimeDealQuestCount(logInLogsByTimeSet)
    }

    useEffect(() => {
      setWeeklyPurchaseCount(purchaseHistoryThisWeek()?.length || 0)
    }, [])

    const getDailyPurchaseHistoryForAlfarm = async () => {
      if (UserStore.dailyPurchaseHistoryCount === null) {
        const result = await backendApis.getDailyPurchaseHistoryForAlfarm(token)
        UserStore.setDailyPurchaseHistoryCount(result?.length)
      }
    }

    const timeDealScreenForWater = async () => {
      Log.send({
        action: 'clickAlfarmTimeDealButton',
        user_id: userId,
      })
      if (
        TimeStore.currentDayJS - lastTimeDealChecked < 3600000 &&
        !waterBottomSheetInfoTimeChecker?.timeDealBrowsingMissionAvailable
      ) {
        return false
      }
      if (
        timeDealQuestCount >= 2 &&
        !waterBottomSheetInfoTimeChecker?.timeDealBrowsingMissionAvailable
      ) {
        return false
      }
      if (canClickButton) {
        setCanClickButton(false)
        const questTimeDeal = {
          screen: 'AlFarmQuestLinkTimeDeal',
          timeStamp: new Date(new Date().getTime() + 1000 * 30),
        }

        window.location.href = `#questTimeDeal.${JSON.stringify(questTimeDeal)}`
        closeBottomSheet()
        setCanClickButton(true)
      }
    }

    const getGganbuList = async () => {
      const result = await backendApis.getGganbuList(token)

      if (!result?.data?.gganbuList) {
        setGganbuLength(0)
      } else if (result?.data?.gganbuList) {
        setGganbuLength(result?.data?.gganbuList?.length)
        setGganbuData(result?.data)
        window.localStorage.setItem('gganbuData', JSON.stringify(result?.data))
      }

      if (result?.data?.inviteFirst) {
        setClearedInviteGganbuFirst(true)
      }
      if (result?.data?.inviteSecond) {
        setClearedInviteGganbuSecond(true)
      }
      if (result?.data?.inviteThird) {
        setClearedInviteGganbuThird(true)
      }
      if (!result?.data?.inviteFirst) {
        setLevel('first')
        setCanClickGganbuButton(true)
      } else if (result?.data?.inviteFirst && !result?.data?.inviteSecond) {
        setLevel('second')
        setCanClickGganbuButton(true)
      } else if (
        result?.data?.inviteFirst &&
        result?.data?.inviteSecond &&
        !result?.data?.inviteThird
      ) {
        setLevel('third')
        setCanClickGganbuButton(true)
      } else {
        setLevel('')
      }
    }

    const clearedInviteGganbuQuest = async (level) => {
      if (!canClickGganbuButton) {
        return false
      }
      if (level === '') {
        return false
      }
      if (canClickGganbuButton) {
        setCanClickGganbuButton(false)
        const result = await backendApis.clearedInviteGganbuQuest(
          token,
          'POST',
          {
            level: level,
          },
        )

        if (result.status === 200) {
          setCanClickGganbuButton(false)
          let tmpFarmData = farmData
          let tempWater = result?.data?.water
          if (UserStore.waterExceeded) {
            tmpFarmData.water += Math.round(result?.data?.water * 0.5)
            tempWater = Math.round(result?.data?.water * 0.5)
          } else {
            tmpFarmData.water += result?.data?.water
          }
          closeBottomSheet()
          setResourceType('water')
          setAddWater(tempWater)
          setShowPopup(true)
          setFarmData(tmpFarmData)
          getGganbuList()
        }
        setCanClickGganbuButton(true)
      }
    }

    const isCherryPickUser = async () => {
      if (
        UserStore?.purchaseHistorys &&
        !farmData?.cherryPickPurchased &&
        new Date() -
          new Date(
            UserStore?.purchaseHistorys?.[
              UserStore?.purchaseHistorys?.length - 1
            ]?.purchasedAt,
          ) >
          1000 * 60 * 60 * 24 * 30
      ) {
        setIsCherryPickingUser(true)
      } else if (
        new Date() - new Date(farmData?.createdAt) > 1000 * 60 * 60 * 24 * 30 &&
        !farmData?.cherryPickPurchased &&
        !UserStore?.purchaseHistorys
      ) {
        setIsCherryPickingUser(true)
      }
    }

    const setWeeklyPurchaseRewardFertClear = async () => {
      // buttonRef.current.disabled = true;

      if (canClickButton) {
        setCanClickButton(false)
        const result = await backendApis.setWeeklyPurchaseReward(token)
        if (result?.status === 200) {
          let tmpFarmData = farmData
          let tempWater = result?.data?.addedValue?.addedWater
          if (UserStore.waterExceeded) {
            tmpFarmData.water += Math.round(
              result?.data?.addedValue?.addedWater * 0.5,
            )
            tempWater = Math.round(result?.data?.addedValue?.addedWater * 0.5)
          } else {
            tmpFarmData.water += result?.data?.addedValue?.addedWater
          }
          closeBottomSheet()
          setResourceType('water')
          setAddWater(tempWater)
          setShowPopup(true)
          setFarmData(tmpFarmData)
          setLastClearedWeeklyPurchaseRewardTime(
            tmpFarmData?.weeklyPurchaseRewardFertClear,
          )
        } else {
          setCanClickButton(true)
          return false
        }
      } else {
        setCanClickButton(true)
        return false
      }
      // buttonRef.current.disabled = false;
    }

    const CherryPickPurchaseRewardWater = () => {
      const cherryPickPurchaseReward = {
        screen: 'AlfarmPurchaseRewardScreen',
        rewardType: 'water',
      }

      window.location.href = `#cherryPickPurchaseReward.${JSON.stringify(
        cherryPickPurchaseReward,
      )}`
    }

    const TimeDealPurchaseButtonClicked = async () => {
      const purchaseRewardWater = {
        screen: 'AlFarmQuestLinkTimeDeal',
        enteringComponent: 'Alfarm_timeDealPurchase',
      }

      window.location.href = `#timeDealPurchase.${JSON.stringify(
        purchaseRewardWater,
      )}`
    }

    const AgreeMarketingPush = async () => {
      //여기서 푸시 동의 api 발송

      const result = await backendApis.updatelastUpdatedAt(token, 'PUT', {
        updateType: 'agreeAppPushAt',
      })

      window?.ReactNativeWebView?.postMessage('agreePushNotification')

      backendApis.giveReward(token, 'PUT', {
        loggingRewardName: 'pushAgree',
        rewardType: 'water',
        valueAmount: 10,
      })
      if (result?.status === 200) {
        let tempFarmData = { ...farmData }
        tempFarmData.agreeAppPushAt = new Date()
        if (UserStore.waterExceeded) {
          tempFarmData.water += 5
          setAddWater(5)
        } else {
          tempFarmData.water += 10
          setAddWater(10)
        }
        setResourceType('water')
        setFarmData(tempFarmData)
        setShowPopup(true)
      }
    }

    const TenMinDealBrowsingButtonClicked = async (
      tenMinDealBrowsingQuestStatus = '',
      checkIn = false,
    ) => {
      if (!farmData || !farmData?.userId) return
      const rewardImage =
        'https://assets.ilevit.com/2b1d6239-69f1-4559-b361-9690521a3213.png'

      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'TenMinDealScreen',
        params: {
          imageBannerUri: rewardImage,
          ratio: 165 / 360,
          enteringComponent: 'Alfarm_tenMinDealScreenBrowsingQuest',
          initialComponent: 'Alfarm_tenMinDealScreenBrowsingQuest',
          isFrom: 'Alfarm_tenMinDealScreenBrowsingQuest',
          tenMinDealBrowsingQuestStatus,
          checkIn,
        },
      })}`
    }

    const DailyPurchaseRewardWaterClicked = async () => {
      const purchaseRewardWater = {
        screen: 'AlfarmPurchaseRewardScreen',
        rewardType: 'dailyPurchaseWater',
      }

      window.location.href = `#purchaseRewardWater.${JSON.stringify(
        purchaseRewardWater,
      )}`
    }

    // tenMinDeal
    const TenMinDealPurchaseButtonClicked = async () => {
      if (!farmData || !farmData?.userId) return
      const rewardImage =
        'https://assets.ilevit.com/7fce66c5-cbfc-48ca-a939-ffe2e46772d1.png'

      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'TenMinDealScreen',
        params: {
          imageBannerUri: rewardImage,
          ratio: 99 / 360,
          enteringComponent: 'Alfarm_tenMinDealScreen',
          initialComponent: 'Alfarm_tenMinDealScreen',
          isFrom: 'Alfarm_tenMinDealScreen',
        },
      })}`
    }

    const tenMinDealAttendanceDailyWater = async (
      tenMinDealBrowsingQuestStatus,
    ) => {
      let checkIn
      if (beforeAttendanceTenMinDeal) {
        setBeforeAttendanceTenMinDeal(false)
        if (
          waterBottomSheetInfoTimeChecker?.tenMinDealAttendanceWaterBottomSheetAvailable
        ) {
          const result = await backendApis.updateTenMinDealDailyAttendance(
            token,
          )
          if (result?.status === 200) {
            let tmpFarmData = farmData
            if (UserStore.waterExceeded) {
              tmpFarmData.water += Math.round(
                result?.data?.addedValue?.addedWater * 0.5,
              )
              setAddWater(
                Math.round(result?.data?.addedValue?.addedWater * 0.5),
              )
            } else {
              tmpFarmData.water += result?.data?.addedValue?.addedWater
              setAddWater(result?.data?.addedValue?.addedWater)
            }

            tmpFarmData.questLogForGetWaterDailyAttendanceForTenMinDeal =
              TimeStore.currentNewDate
            closeBottomSheet()
            setResourceType('water')
            // setShowPopup(true);
            setFarmData(tmpFarmData)
            if (result?.data?.addedValue?.addedWater > 0) {
              checkIn = true
            }
            TenMinDealBrowsingButtonClicked(
              tenMinDealBrowsingQuestStatus,
              checkIn,
            )
          } else {
            setInviteResultStatus('errorAlert')
            setBeforeAttendanceTenMinDeal(true)
            return false
          }
          // setBeforeAttendanceTenMinDeal(true); // 필요없는 것 같아서 지움
          return true
        } else {
          // setBeforeAttendanceTenMinDeal(true);

          setBeforeAttendanceTenMinDeal(false)
          return false
        }
      }
    }

    const attendanceDailyWater = async () => {
      if (canClickButton) {
        setCanClickButton(false)
        if (
          waterBottomSheetInfoTimeChecker?.attendanceWaterBottomSheetAvailable
        ) {
          const result = await backendApis.getWaterDailyAttendance(token)
          if (result.status === 200) {
            let tmpFarmData = farmData
            if (UserStore.waterExceeded) {
              tmpFarmData.water += Math.round(
                result?.data?.addedValue?.addedWater * 0.5,
              )
              setAddWater(
                Math.round(result?.data?.addedValue?.addedWater * 0.5),
              )
            } else {
              tmpFarmData.water += result?.data?.addedValue?.addedWater
              setAddWater(result?.data?.addedValue?.addedWater)
            }

            tmpFarmData.questLogForGetWaterDailyAttendance =
              TimeStore.currentNewDate
            closeBottomSheet()
            setResourceType('water')
            setShowPopup(true)
            setFarmData(tmpFarmData)
            if (farmData?.resurrectBonus) {
              ModalStore.setIsModalOpen('resurrectBonusAttendanceEvent', {
                imgType: 'waterTomorrow',
              })
            }
          } else {
            setInviteResultStatus('errorAlert')
            setCanClickButton(true)
            return false
          }
          setCanClickButton(true)
          return true
        } else {
          setCanClickButton(true)
          return false
        }
      }
    }

    const browsingMissionButtonClicked = async () => {
      const purchaseData = {
        enteringComponent: 'Alfarm_DailyPurchaseWaterRewardScreen',

        text: '올팜',
        source:
          clearedPurchaseRewardCount < 2
            ? 'https://assets.ilevit.com/1b6ec647-f56b-4c25-8cda-daec68e79d97.png'
            : 'https://assets.ilevit.com/f86d8803-3344-495f-b03b-602ff91717db.png',
        isTimerOn: true,
        timerText: '미션 완료까지 ',
        isTimerEndText: '축하합니다! 물을 받았어요',
        timeStamp: new Date(new Date().getTime() + 1000 * 30),
        rewardText: '상품을 구매하면 물 700g을 받아요',
        questAmount: '30',
        isFrom: 'bottomSheetBrowsingMission',
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
    }

    const ClearReviewCheckQuest = async () => {
      const result = await backendApis.clearReviewCheckQuest(token)
      if (result.status === 200) {
        let tmpFarmData = farmData
        if (UserStore.waterExceeded) {
          tmpFarmData.water += Math.round(result?.data?.addedWater * 0.5)
          setAddWater(Math.round(result?.data?.addedWater * 0.5))
        } else {
          tmpFarmData.water += result?.data?.addedWater
          setAddWater(result?.data?.addedWater)
        }

        closeBottomSheet()
        setResourceType('water')
        setShowPopup(true)
        setFarmData(tmpFarmData)
        setCheckedReviewModal(false)
      } else {
        setInviteResultStatus('errorAlert')
        return false
      }
    }

    const adItemBrowsingQuest = async (availability) => {
      let waterBrowsingAdItem = JSON.parse(
        window.localStorage.getItem('waterBrowsingAdItem'),
      )
      if (!waterBrowsingAdItem || availability) {
        const result = await backendApis.getBrowsingAdItem(token)
        if (result?.status === 200) {
          waterBrowsingAdItem = {
            itemId: result?.data?._id,
            isAdItem: result?.data?.adInfo?.isAdItem,
            itemInfo: result?.data,
          }
          window.localStorage.setItem(
            'waterBrowsingAdItem',
            JSON.stringify(waterBrowsingAdItem),
          )
        } else {
          ToastStore.toastOn({
            type: 'error',
            message: '[네트워크 에러] 다시 시도해주세요',
            duration: 3000,
          })
          return
        }
      }
      const adItemBrowsing = {
        text: '올팜',

        isTimerOn: availability,
        isBannerOnly: !availability,
        timerText: `초간 구경하면 물 20g을 받아요`,
        isTimerEndText: '축하합니다! 물 20g을 받았어요',
        itemId: waterBrowsingAdItem?.itemId,
        enteringComponent: 'Alfarm_WaterBrowsingAdQuest',
        source:
          'https://assets.ilevit.com/08215223-2a7c-4934-9e54-5e68c0e5412c.png',
        isAdItem: waterBrowsingAdItem?.isAdItem,
        itemInfo: waterBrowsingAdItem?.itemInfo,
        payload: {
          rewardAmount: 20,
          rewardType: 'water',
          loggingRewardName: 'waterBrowsingAdQuest',
          lastUpdateName: 'lastWaterBrowsingAdQuestAt',
        },
      }

      closeBottomSheet()
      window.location.href = `#adItemBrowsing.${JSON.stringify(adItemBrowsing)}`
    }
    const photoReviewReward = async () => {
      const result = await backendApis.photoReviewReward(token)
      if (result.status === 200) {
        let tmpFarmData = farmData
        if (UserStore.waterExceeded) {
          tmpFarmData.water += Math.round(result?.data?.addedWater * 0.5)
          setAddWater(Math.round(result?.data?.addedWater * 0.5))
        } else {
          tmpFarmData.water += result?.data?.addedWater
          setAddWater(result?.data?.addedWater)
        }

        closeBottomSheet()
        setResourceType('water')
        setShowPopup(true)
        setFarmData(tmpFarmData)
        setCheckedReviewModal(false)
      } else {
        setInviteResultStatus('errorAlert')
        return false
      }
    }

    const PurchasedCheckInReward = async () => {
      const result = await backendApis.clearedPurchasedCheckInReward(token)
      if (result?.data?.success) {
        let tmpFarmData = farmData
        if (UserStore.waterExceeded) {
          tmpFarmData.water += 250
          setAddWater(250)
        } else {
          tmpFarmData.water += 500
          setAddWater(500)
        }
        tmpFarmData?.purchasedCheckInReward?.rewardHistory.push(new Date())
        // setPurchasedCheckInRewardCount(purchasedCheckInRewardCount + 1);
        setResourceType('water')
        setShowPopup(true)
        setFarmData(tmpFarmData)
      } else {
        setInviteResultStatus('errorAlert')
        return false
      }
    }

    const AvailableTommorowText = () => {
      return (
        <button
          className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
        >
          내일 가능
        </button>
      )
    }

    const PhoneBookGganbu = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (waterBottomSheetInfoTimeChecker?.checkedPhoneBookGganbuAvailable) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              window.location.href = `#alfarmCheckContactsPermission`
              checkedPhoneBookGganbu()
              closeBottomSheet()
            }}
          >
            찾아보기
          </button>
        )
      } else {
        return <AvailableTommorowText />
      }
    }

    const DailyWaterButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (
        waterBottomSheetInfoTimeChecker?.attendanceWaterBottomSheetAvailable
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%]
          ${buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`}
          ${
            giveWaterCount === 4 &&
            !farmData?.questLogForGetWaterDailyAttendance
              ? `z-[12003]`
              : `z-[2]`
          }
          `}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              attendanceDailyWater()
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      }
      if (
        !waterBottomSheetInfoTimeChecker?.attendanceWaterBottomSheetAvailable
      ) {
        return <AvailableTommorowText />
      }
    }

    const AgreeMarketingPushButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)
      return (
        <>
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              AgreeMarketingPush()
              closeBottomSheet()
            }}
          >
            동의하기
          </button>
        </>
      )
    }

    const PurchasedCheckInRewardButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (clearedPurchasedCheckInReward && purchasedCheckInRewardCount < 4) {
        return <AvailableTommorowText />
      } else {
        return (
          <>
            <button
              className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
                buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true)
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)
                // AgreeMarketingPush();
                PurchasedCheckInReward()
                closeBottomSheet()
              }}
            >
              출석하기
            </button>
          </>
        )
      }
    }

    const TenMinDealPurchaseButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      return (
        <button
          className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
            buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
          }`}
          onPointerDown={() => {
            setButtonClicked(true)
          }}
          onPointerCancel={() => {
            setButtonClicked(false)
          }}
          onPointerUp={() => {
            setButtonClicked(false)
            TenMinDealPurchaseButtonClicked()
            closeBottomSheet()
          }}
        >
          구매하기
        </button>
      )
    }

    const DailyPurchaseRewardWaterButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (clearedPurchaseRewardCount < 2) {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true)
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)
                DailyPurchaseRewardWaterClicked()
                closeBottomSheet()
              }}
            >
              구매하기
            </button>
          </>
        )
      } else {
        return <AvailableTommorowText />
      }
    }

    const PhotoReviewButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (
        photoReviewItemsInfo?.filter(
          (e) => e?.isReviewed === true && e?.isRewarded === false,
        ).length > 0
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              photoReviewReward()
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      } else {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true)
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)
                ModalStore.setIsModalOpen('photoReview')
                closeBottomSheet()
              }}
            >
              후기적기
            </button>
          </>
        )
      }
    }

    const FightingGganbuButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      return (
        <button
          className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
            ${buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`}`}
          onPointerDown={() => setButtonClicked(true)}
          onPointerCancel={() => setButtonClicked(false)}
          onPointerUp={async () => setButtonClicked(false)}
          onClick={async () => {
            if (!canClickButton) return
            setCanClickButton(false)
            const result = await backendApis.fightingGganbu()
            if (result?.data?.success) {
              let tmpFarmData = farmData
              tmpFarmData.water += result?.data?.water
              setAddWater(result?.data?.water)
              closeBottomSheet()
              setResourceType('water')
              setShowPopup(true)
              setFarmData(tmpFarmData)
            }
            setCanClickButton(true)
          }}
        >
          응원하기
        </button>
      )
    }

    const RewardedAdButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (isInviteWaterMissionAvailable) {
        if (UserStore.isRewardAdReady) {
          return (
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
            ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
              onPointerDown={() => setButtonClicked(true)}
              onPointerCancel={() => setButtonClicked(false)}
              onPointerUp={async () => setButtonClicked(false)}
              onClick={() => {
                if (!canClickButton) return
                setCanClickButton(false)
                if (UserStore.isRewardAdReady === true) {
                  ModalStore.setIsModalOpen('rewardAdModalWater')
                }
                setCanClickButton(true)
              }}
              disabled={!UserStore.isRewardAdReady}
            >
              광고 보기
            </button>
          )
        } else {
          return (
            <button
              className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
            >
              <div className='text-[#442b22]'>광고 준비중</div>
            </button>
          )
        }
      }
      return <AvailableTommorowText />
    }

    const BrowsingMissionButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (isBrowsingMissionAvailable) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              browsingMissionButtonClicked()
              closeBottomSheet()
            }}
          >
            구경하기
          </button>
        )
      } else {
        return (
          <>
            <button
              className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
            >
              <div
                style={{
                  color: '#442b22',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div>
                  {browsingMissionClearedAt && (
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        setIsBrowsingMissionAvailable(true)
                      }}
                      timeStamp={
                        new Date(browsingMissionClearedAt?.add(4, 'h'))
                      }
                      timerMinutes={0}
                      color='#442b22'
                    />
                  )}
                </div>
              </div>
            </button>
          </>
        )
      }
    }

    const SearchMissionButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)
      if (isSearchMissionAvailable) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              searchMissionButtonClicked()
              closeBottomSheet()
            }}
          >
            검색하기
          </button>
        )
      } else if (isSearchMissionRewardReceivable) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setIsSearchMissionButtonClicked(true)
            }}
            onPointerCancel={() => {
              setIsSearchMissionButtonClicked(false)
            }}
            onPointerUp={async () => {
              let result = await backendApis.giveSearchMissionReward()
              setIsSearchMissionButtonClicked(false)
              closeBottomSheet()

              let tempFarmData = { ...farmData }
              tempFarmData.agreeAppPush = new Date()
              if (UserStore.waterExceeded) {
                tempFarmData.water += 5
                setAddWater(5)
              } else {
                tempFarmData.water += 10
                setAddWater(10)
              }
              setResourceType('water')
              setFarmData(tempFarmData)
              setShowPopup(true)
              if (result?.data?.missionHunterPurchaseTriggerd) {
                let missionHunterData =
                  await backendApis.getSpecificOngoingAlfarmInfo({
                    fieldsProjected: JSON.stringify({
                      missionHunterPurchaseInfo: 1,
                    }),
                  })
                triggerMissionHunter(missionHunterData?.data)
              }
            }}
          >
            받기
          </button>
        )
      } else {
        return (
          <>
            <button
              className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
            >
              <div
                style={{
                  color: '#442b22',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div>
                  {searchMissionClearedAt && (
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        setIsSearchMissionAvailable(true)
                      }}
                      timeStamp={new Date(searchMissionClearedAt?.add(4, 'h'))}
                      timerMinutes={0}
                      color='#442b22'
                    />
                  )}
                </div>
              </div>
            </button>
          </>
        )
      }
    }

    const TimeDealScreenForWaterButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      let buttonType = 'third'

      if (
        (TimeStore.currentDayJS - lastTimeDealChecked > 3600000 &&
          timeDealQuestCount < 2) ||
        waterBottomSheetInfoTimeChecker?.timeDealBrowsingMissionAvailable
      ) {
        buttonType = 'first'
      } else if (
        TimeStore.currentDayJS - lastTimeDealChecked < 3600000 &&
        timeDealQuestCount < 2 &&
        !waterBottomSheetInfoTimeChecker?.timeDealBrowsingMissionAvailable
      ) {
        buttonType = 'second'
      } else if (timeDealQuestCount >= 2) {
        buttonType = 'third'
      }

      if (buttonType === 'first') {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              timeDealScreenForWater()
              closeBottomSheet()
            }}
          >
            구경하기
          </button>
        )
      }
      if (buttonType === 'second') {
        return (
          <>
            <button
              className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
            >
              <div
                style={{
                  color: '#442b22',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div>
                  <WhiteTextTimer
                    onTimeEnd={() => {
                      setLastTimeDealChecked(dayjs(0))
                    }}
                    timeStamp={completeTimeDealQuest}
                    timerMinutes={60}
                    color='#442b22'
                  />
                </div>
                <div
                  style={{
                    color: '#442b22',
                  }}
                >
                  {2 - timeDealQuestCount}번 더 가능
                </div>
              </div>
            </button>
          </>
        )
      }
      if (buttonType === 'third') {
        return <AvailableTommorowText />
      }
    }

    // tenMinDeal
    const TenMinDealBrowsingButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      const now = dayjs()
      // const browsingAvailableOpenTimeAM = now.startOf("day").add(8, "hour");
      // const browsingAvailableOpenTimePM = now.startOf("day").add(20, "hour");

      // let timeStamp;
      // if (now?.isBefore(browsingAvailableOpenTimeAM)) {
      //   timeStamp = browsingAvailableOpenTimeAM
      //     ?.clone()
      //     ?.subtract(1, "hour")
      //     ?.toDate();
      // } else if (
      //   now?.isAfter(browsingAvailableOpenTimeAM.clone().add(10, "minute"))
      // ) {
      //   timeStamp = browsingAvailableOpenTimePM
      //     ?.clone()
      //     ?.subtract(1, "hour")
      //     ?.toDate();
      // }

      let buttonDisabledText = '8시에 가능'
      const isAM = now?.hour() < 12
      if (isAM) {
        buttonDisabledText = '저녁 8시'
      } else {
        buttonDisabledText = '내일 8시'
      }
      if (tenMinDealBrowsingQuestStatus === 'available') {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true) // browsing btn clicked
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)
                beforeAttendanceTenMinDeal &&
                beforeAttendanceTenMinDeal === true
                  ? tenMinDealAttendanceDailyWater(
                      tenMinDealBrowsingQuestStatus,
                    ) // 출석 전인게 맞으면 클릭 퍼널을 다르게
                  : TenMinDealBrowsingButtonClicked(
                      tenMinDealBrowsingQuestStatus,
                    )
                closeBottomSheet()
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'waterBottomSheet',
                  locationName: 'tenMinDealBrowsing',
                  eventType: 'click',
                  eventName: 'tenMinDealBrowsingBtnClickAvailable',
                  data: { testName: 'tenMinDealBrowsing1230' },
                  collection: 'UserAlfarmClickLog',
                })
              }}
            >
              {beforeAttendanceTenMinDeal ? '출석하기' : '구경하기'}
            </button>
          </>
        )
      } else if (tenMinDealBrowsingQuestStatus === 'waiting') {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true) // browsing btn clicked
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)
                TenMinDealBrowsingButtonClicked(tenMinDealBrowsingQuestStatus)
                closeBottomSheet()
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'waterBottomSheet',
                  locationName: 'tenMinDealBrowsing',
                  eventType: 'click',
                  eventName: 'tenMinDealBrowsingBtnClickWaiting',
                  data: { testName: 'tenMinDealBrowsing1230' },
                  collection: 'UserAlfarmClickLog',
                })
              }}
            >
              출석하기
            </button>
          </>
        )
      } else if (tenMinDealBrowsingQuestStatus === 'done') {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true) // browsing btn clicked
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                setButtonClicked(false)
                TenMinDealBrowsingButtonClicked(tenMinDealBrowsingQuestStatus)
                closeBottomSheet()
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'waterBottomSheet',
                  locationName: 'tenMinDealBrowsing',
                  eventType: 'click',
                  eventName: 'tenMinDealBrowsingBtnClickDone',
                  data: { testName: 'tenMinDealBrowsing1230' },
                  collection: 'UserAlfarmClickLog',
                })
              }}
            >
              구매하기
            </button>
          </>
        )
      } else {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442b22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            {buttonDisabledText}
            <br /> 오픈
          </button>
        )
      }
    }

    const LiveReviewCheck = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (!checkedReviewModal & !clearedReviewQuest) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              ModalStore.setIsModalOpen('review')
              setCheckedReviewModal(true)
            }}
          >
            후기보기
          </button>
        )
      } else if (checkedReviewModal && !clearedReviewQuest) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              ClearReviewCheckQuest()
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            내일 가능
          </button>
        )
      }
    }

    // winnerDealButton 파일을 새로 만들어서 옮겼음
    // const WinnerdealButton = () => {
    //   const [buttonClicked, setButtonClicked] = useState(false)

    //   const winnerDealRewardedAdCheck =
    //     ABStore?.winnerDealRewardedAd &&
    //     (UserStore?.cherryPickerForAd ||
    //       // farmData?.userId === '65113f8c3dcc855d62e43a8b' ||
    //       farmData?.userId === '626aa6a6c4d18f4110ecd6cb')

    //   const ImageSprite = () => {
    //     if (
    //       TimeStore.currentDayJS >
    //         dayjs(DealAutomationStore.firstWinnerDealData.timeRangeStart) &&
    //       TimeStore.currentDayJS <
    //         dayjs(DealAutomationStore.firstWinnerDealData.timeRangeEnd)
    //     ) {
    //       return {
    //         modalImage: DealAutomationStore.firstWinnerDealData.background,
    //         purchaseScreenBanner:
    //           DealAutomationStore.firstWinnerDealData.purchaseScreenBanner,
    //         purchaseScreenText:
    //           DealAutomationStore.firstWinnerDealData.purchaseScreenText,
    //       }
    //     } else if (
    //       TimeStore.currentDayJS >
    //         dayjs(DealAutomationStore.secondWinnerDealData.timeRangeStart) &&
    //       TimeStore.currentDayJS <
    //         dayjs(DealAutomationStore.secondWinnerDealData.timeRangeEnd)
    //     ) {
    //       return {
    //         modalImage: DealAutomationStore.secondWinnerDealData.background,
    //         purchaseScreenBanner:
    //           DealAutomationStore.secondWinnerDealData.purchaseScreenBanner,
    //         purchaseScreenText:
    //           DealAutomationStore.secondWinnerDealData.purchaseScreenText,
    //       }
    //     }
    //   }

    //   const mainAdItemIds = winnerDealMainAdData?.itemIds || []
    //   const subAdItem1Ids = winnerDealSubAdData?.[0]
    //     ? winnerDealSubAdData?.[0]?.itemIds
    //     : []
    //   const subAdItem2Ids = winnerDealSubAdData?.[1]
    //     ? winnerDealSubAdData?.[1]?.itemIds
    //     : []

    //   const combinedItemIds = [
    //     ...mainAdItemIds,
    //     ...subAdItem1Ids,
    //     ...subAdItem2Ids,
    //   ]
    //   const mainAdId = winnerDealMainAdData?._id
    //   const subAdId1 = winnerDealSubAdData?.[0]?._id || null
    //   const subAdId2 = winnerDealSubAdData?.[1]?._id || null
    //   const subAdIds = [subAdId1, subAdId2].filter((e) => e)

    //   if (waterBottomSheetInfoTimeChecker?.clearedWinnerDealHistoryAvailable) {
    //     if (!winnerDealRewardedAdCheck) {
    //       return (
    //         <button
    //           className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
    //             buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
    //           }`}
    //           onPointerDown={() => {
    //             setButtonClicked(true)
    //           }}
    //           onPointerCancel={() => {
    //             setButtonClicked(false)
    //           }}
    //           onPointerUp={() => {
    //             setButtonClicked(false)

    //             window.location.href = `#generalNavigator.${JSON.stringify({
    //               screen: 'AlfarmAdPurchaseScreen',
    //               params: {
    //                 title: ImageSprite()?.purchaseScreenText,
    //                 image: ImageSprite()?.purchaseScreenBanner,
    //                 ratio: 99 / 360,
    //                 enteringComponent: 'Alfarm_winnerDealForAd',
    //                 questReward: 'water',
    //                 questRewardType: 'water',
    //                 questRewardText: `초간 구경하면 물 10g을 받아요`,
    //                 questTimer: 0.5,
    //                 adId: mainAdId,
    //                 subAdId: subAdIds,
    //                 itemIds: combinedItemIds,
    //                 isTimerOn: !clearedWinnerDealAd,
    //               },
    //             })}`
    //             closeBottomSheet()
    //           }}
    //         >
    //           구경하기
    //         </button>
    //       )
    //     } else if (winnerDealRewardedAdCheck) {
    //       return (
    //         <button
    //           className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
    //             buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
    //           }`}
    //           onPointerDown={() => {
    //             setButtonClicked(true)
    //           }}
    //           onPointerCancel={() => {
    //             setButtonClicked(false)
    //           }}
    //           onPointerUp={() => {
    //             setButtonClicked(false)
    //             ModalStore?.setIsModalOpen('rewardAdModalForWinnerDeal')
    //             closeBottomSheet()
    //           }}
    //         >
    //           구경하기
    //         </button>
    //       )
    //     }
    //   } else {
    //     return (
    //       <button
    //         className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
    //       >
    //         완료
    //       </button>
    //     )
    //   }
    // }

    const BrowsingBrandDealQuest = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (waterBottomSheetInfoTimeChecker?.brandDealBrowsingQuestAvailable) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              window.location.href = `#alfarmDealScreen.${JSON.stringify({
                dealType: 'alfarmBrand',
                title: ImageSprite()?.purchaseScreenText,
                image: ImageSprite()?.purchaseScreenBanner,
                ratio: 99 / 360,
                enteringComponent: 'Alfarm_alfarmBrandDealScreenBrowsingQuest',
                enteringLocation: 'waterBottomSheet',
              })}`
              ModalStore.setIsModalOpen('basic')
              setButtonClicked(false)
              closeBottomSheet()
            }}
          >
            구경하기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442b22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            완료
          </button>
        )
      }
    }

    const QuestComponent = ({
      iconSrc,
      title,
      subtitle,
      buttonComponent,
      subtitleIconSrc,
      infoContent = '',
      newPing = false,
    }) => {
      return (
        <div className='relative flex px-[4vw] pt-[2vw] pb-[4vw] bg-white border-b-[0.1vw] border-[#D3D7DD]'>
          <div className='flex items-center'>
            <img
              src={iconSrc}
              alt=''
              className='mr-[2vw] w-[10.6vw] h-[10.6vw]'
            />
            <div>
              <div className='text-[4.4vw] text-[#525964]'>{title}</div>
              {infoContent !== '' && (
                <div
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setInfoContent(`${infoContent}`)
                    setShowInfoIcon(true)
                    setTimeout(() => {
                      setShowInfoIcon(false)
                    }, 5000)
                  }}
                >
                  <img
                    className='w-[5vw] h-[5vw] absolute top-0 mt-[2.6vw] right-[35vw]'
                    src='/icon/fertInformation.png'
                    alt=''
                  />
                </div>
              )}
              <div className='flex flex-row items-center'>
                {subtitleIconSrc && (
                  <img
                    src={subtitleIconSrc}
                    alt=''
                    className='mr-[0.5vw] w-[5vw] h-[5vw]'
                  />
                )}
                <div className='text-[3.6vw] text-[#6D7680] '>{subtitle}</div>
              </div>
              {newPing && (
                <img
                  style={{
                    zIndex: 1,
                    width: '8%',
                    position: 'absolute',
                    left: '10vw',
                    bottom: '10vw',
                  }}
                  className='wobble-ver-left'
                  src='/icon/newPing.png'
                  alt=''
                />
              )}
            </div>
          </div>
          {buttonComponent}
        </div>
      )
    }

    const AltoonMission = ({ missionType, missionCount }) => {
      const [buttonClicked, setButtonClicked] = useState(false)

      const giveMissionReward = async () => {
        if (canClickButton) {
          setCanClickButton(false)
          const result = await backendApis.rewardAltoonMissionReward(
            token,
            'PUT',
            {
              missionType,
            },
          )
          if (result?.data?.success === true) {
            let tmpFarmData = farmData
            let tmpAltoonData = altoonData

            if (UserStore.waterExceeded) {
              tmpFarmData.water += Math.round(
                result?.data?.addedValue?.addedWater * 0.5,
              )
              setAddWater(
                Math.round(result?.data?.addedValue?.addedWater * 0.5),
              )
            } else {
              tmpFarmData.water += result?.data?.addedValue?.addedWater
              setAddWater(result?.data?.addedValue?.addedWater)
            }

            tmpAltoonData[`${missionType}ClearedAt`] = new Date()
            closeBottomSheet()
            setResourceType('water')
            setShowPopup(true)
            setFarmData(tmpFarmData)

            setAltoonData(tmpAltoonData)
          } else {
            setInviteResultStatus('errorAlert')
            setCanClickButton(true)
            return false
          }
          setCanClickButton(true)
          return true
        } else {
          setCanClickButton(true)
          return false
        }
      }

      if (altoonData?.[`${missionType}ClearedAt`]) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[40%]`}
          >
            완료
          </button>
        )
      } else if (altoonData?.altoonReadLog?.length >= missionCount) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[40%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              const rewardGiven = giveMissionReward()
              if (rewardGiven === true) {
                if (missionCount === 1) {
                  setClearedAltoonMissionOne(true)
                } else if (missionCount === 2) {
                  setClearedAltoonMissionTwo(true)
                } else if (missionCount === 5) {
                  setClearedAltoonMissionFive(true)
                }
              }
              closeBottomSheet()
            }}
          >
            보상받기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[40%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              // const webUrl = 'http://172.30.1.77:3001/altoon-main'
              const webUrl = 'https://alwayzwebscreen.ilevit.com/altoon-main'
              const redirectSearchParams = new URLSearchParams({
                code: 'alfarmMission',
                randomString: Math.random().toString(36).slice(2, 10),
              })
              const prop = {
                screen: 'WebScreen',
                prop: {
                  eventPageMapping: {
                    redirectUrl: `${webUrl}/?${redirectSearchParams}`,
                  },
                },
              }
              window.location.href = `#navigate.${JSON.stringify(prop)}`
            }}
          >
            바로가기
          </button>
        )
      }
    }

    const DramaMission = ({ missionType, missionCount }) => {
      const [buttonClicked, setButtonClicked] = useState(false)

      const giveMissionReward = async () => {
        if (canClickButton) {
          setCanClickButton(false)
          const result = await backendApis.rewardDramaMissionReward(
            token,
            'PUT',
            {
              missionType,
            },
          )
          if (result?.data?.success === true) {
            let tmpFarmData = farmData
            let tmpDramaData = dramaData

            if (UserStore.waterExceeded) {
              tmpFarmData.water += Math.round(
                result?.data?.addedValue?.addedWater * 0.5,
              )
              setAddWater(
                Math.round(result?.data?.addedValue?.addedWater * 0.5),
              )
            } else {
              tmpFarmData.water += result?.data?.addedValue?.addedWater
              setAddWater(result?.data?.addedValue?.addedWater)
            }

            tmpDramaData[`${missionType}ClearedAt`] = new Date()
            closeBottomSheet()
            setResourceType('water')
            setShowPopup(true)
            setFarmData(tmpFarmData)

            setDramaData(tmpDramaData)
          } else {
            setInviteResultStatus('errorAlert')
            setCanClickButton(true)
            return false
          }
          setCanClickButton(true)
          return true
        } else {
          setCanClickButton(true)
          return false
        }
      }

      if (dramaData?.[`${missionType}ClearedAt`]) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[40%]`}
          >
            완료
          </button>
        )
      } else if (dramaData?.dramaWatchLog?.length >= missionCount) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[40%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              const rewardGiven = giveMissionReward()
              if (rewardGiven === true) {
                if (missionCount === 1) {
                  setClearedDramaMissionOne(true)
                }
              }
              closeBottomSheet()
            }}
          >
            보상받기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[40%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              const webUrl = 'https://alwayz-drama-front.ilevit.com'
              const redirectSearchParams = new URLSearchParams({
                code: 'alfarmMission',
                randomString: Math.random().toString(36).slice(2, 10),
              })
              const prop = {
                screen: 'Drama',
                prop: {
                  isFrom: 'alfarm',
                  eventPageMapping: {
                    redirectUrl: `${webUrl}/?${redirectSearchParams}`,
                  },
                },
              }
              window.location.href = `#navigate.${JSON.stringify(prop)}`
            }}
          >
            바로가기
          </button>
        )
      }
    }

    const DrinkWater = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (farmData?.drinkWaterQuest?.length >= 8) {
        return <AvailableTommorowText />
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              ModalStore.setIsModalOpen('drinkWater')
            }}
          >
            바로가기
          </button>
        )
      }
    }

    const WeeklyPurchaseRewardWaterButtonNew = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (weeklyPurchaseCount <= 2) {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                // 구매 관련 설명 모달 팝업
              }}
            >
              {weeklyPurchaseCount}회<br />
              구매 완료
            </button>
          </>
        )
      } else if (
        weeklyPurchaseCount > 2 &&
        lastClearedWeeklyPurchaseRewardTime <
          dayjs()
            .utcOffset(0)
            .set('hour', 15)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .day(+6)
            .toDate() &&
        dayjs().startOf('week').toDate() <= lastClearedWeeklyPurchaseRewardTime
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            <div
              style={{
                color: '#442b22',
              }}
            >
              다음주 가능
            </div>
          </button>
        )
      } else if (
        weeklyPurchaseCount > 2 &&
        dayjs().startOf('week').toDate() >
          dayjs(lastClearedWeeklyPurchaseRewardTime).toDate()
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              setWeeklyPurchaseRewardFertClear()
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            <div
              style={{
                color: '#442b22',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  color: '#442b22',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    color: '#442b22',
                  }}
                >
                  다음주 가능
                </div>
              </div>
            </div>
          </button>
        )
      }
    }

    const PurchaseMissionComponentButton = (
      buttonPressed,
      setButtonPressed,
      onClickHandler,
    ) => {
      const [buttonClicked, setButtonClicked] = useState(false)
      return (
        <>
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              onClickHandler()
              closeBottomSheet()
            }}
          >
            구매하기
          </button>
        </>
      )
    }

    const DailyInviteComponentButton = (
      buttonPressed,
      setButtonPressed,
      onClickHandler,
    ) => {
      const [buttonClicked, setButtonClicked] = useState(false)
      return (
        <>
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              onClickHandler()
              closeBottomSheet()
            }}
          >
            초대하기
          </button>
        </>
      )
    }

    const InviteGganbuQuestOne = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (gganbuLength >= 1 && !clearedInviteGganbuFirst) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              clearedInviteGganbuQuest(level)
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      } else if (!clearedInviteGganbuFirst) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              shareKakao()
              closeBottomSheet()
            }}
          >
            초대하기
          </button>
        )
      }
    }

    const InviteGganbuQuestTwo = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (
        gganbuLength >= 5 &&
        clearedInviteGganbuFirst &&
        !clearedInviteGganbuSecond
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              clearedInviteGganbuQuest(level)
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      } else if (clearedInviteGganbuFirst && !clearedInviteGganbuSecond) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              shareKakao()
              closeBottomSheet()
            }}
          >
            초대하기
          </button>
        )
      }
    }

    const InviteGganbuQuestThree = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (
        gganbuLength >= 10 &&
        clearedInviteGganbuFirst &&
        clearedInviteGganbuSecond &&
        !clearedInviteGganbuThird
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              clearedInviteGganbuQuest(level)
              closeBottomSheet()
            }}
          >
            받기
          </button>
        )
      } else if (
        gganbuLength < 10 &&
        clearedInviteGganbuFirst &&
        clearedInviteGganbuSecond &&
        !clearedInviteGganbuThird
      ) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              shareKakao()
              closeBottomSheet()
            }}
          >
            초대하기
          </button>
        )
      }
    }

    const PurchaseMissionComponent = ({ config }) => (
      <div style={{ position: 'relative' }}>
        {!config?.skipBorder && (
          <div style={{ width: '100%', borderTop: '0.5px solid #ddd' }} />
        )}

        {config?.infoButtonOn && (
          <button
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              config?.infoButtonOnClick()
            }}
            style={{
              position: 'absolute',
              marginTop: '3vw',
              right: '26vw',
            }}
          >
            <div>
              <img
                style={{
                  width: '4vw',
                }}
                src='/icon/fertInformation.png'
                alt=''
              />
            </div>
          </button>
        )}
        <div
          style={{
            width: '100%',
            height: '12vh',
            maxHeight: '90px',
            display: 'flex',
            alignItems: 'center',
            padding: 12,
            paddingRight: 8,
          }}
        >
          <img
            style={{
              zIndex: 1,
              width: '14vw',
              height: '14vw',
              marginRight: 8,
            }}
            src={config.thumbnail}
            alt=''
          />
          <div
            id='information'
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              height: '100%',
              maxHeight: '60px',
            }}
          >
            <div
              id='title&reward'
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div
                id='title'
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'maplestory',
                  fontSize: '4vw',
                  marginBottom: '2px',
                  color: '#525964',
                }}
              >
                {config.title}
              </div>
              <div
                id='reward'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  // border: "1px solid black",
                }}
              >
                <img
                  style={{
                    zIndex: 1,
                    width: '5vw',
                    height: '5vw',
                    marginRight: 2,
                  }}
                  src='/waterBottomSheet/droplet.png'
                  alt=''
                />
                <div
                  id='waterAmount'
                  style={{
                    fontFamily: 'maplestory',
                    fontSize: '3.5vw',
                    color: '#525964',
                  }}
                >
                  {config.reward}
                </div>
              </div>
            </div>
            <div
              id='info'
              style={{
                fontFamily: 'maplestory',
                color: '#A4ACB4',
                fontSize: '3vw',
              }}
            >
              {config.info}
            </div>
          </div>
          {config.button}
        </div>
      </div>
    )

    const ShareButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (isInviteWaterMissionAvailable) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              shareLink()
            }}
          >
            초대하기
          </button>
        )
      } else {
        return <AvailableTommorowText />
      }
    }

    const QuizButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)
      if (
        canSolveQuiz &&
        solvedQuizCount < AlfarmAdStore.quizAdDailyMaxExposureCount
      ) {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
                buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
              }`}
              onPointerDown={() => {
                setButtonClicked(true)
              }}
              onPointerCancel={() => {
                setButtonClicked(false)
              }}
              onPointerUp={() => {
                closeBottomSheet()
                ModalStore.setIsModalOpen('alfarmQuiz')
              }}
            >
              퀴즈풀기
            </button>
            <div
              className='blinking-slow'
              style={{
                position: 'absolute',
                background: '#EA3323',
                borderRadius: '5vw',
                paddingTop: '1.1vw',
                paddingBottom: '1.1vw',
                paddingRight: '1.1vw',
                paddingLeft: '1.1vw',
                right: '3.0vw',
                // right: '85.0vw',
                top: '2.5vw',
                zIndex: 999,
              }}
            ></div>
          </>
        )
      } else if (
        !canSolveQuiz &&
        solvedQuizCount < AlfarmAdStore.quizAdDailyMaxExposureCount
      ) {
        return (
          <>
            <button
              className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
            >
              <div
                style={{
                  color: '#442b22',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div>
                  <WhiteTextTimer
                    onTimeEnd={() => {
                      setCanSolveQuiz(true)
                    }}
                    timeStamp={
                      farmData?.solvedQuizHistory
                        ? new Date(
                            farmData?.solvedQuizHistory?.[
                              farmData?.solvedQuizHistory?.length - 1
                            ]?.solvedAt,
                          )
                        : new Date()
                    }
                    timerMinutes={
                      60 * AlfarmAdStore.quizAdTimeIntervalBetweenExposure
                    }
                    color='#442b22'
                  />
                </div>
                <div
                  style={{
                    color: '#442b22',
                  }}
                >
                  뒤에 가능
                </div>
              </div>
            </button>
          </>
        )
      } else {
        return <AvailableTommorowText />
      }
    }

    const ButtonComponent = ({
      availableText,
      inavailableText,
      availability,
      onPressAvailability,
      onPressInavailability,
    }) => {
      const [buttonPressed, setButtonPressed] = useState(false)

      //가능하면
      if (availability) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonPressed ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonPressed(true)
            }}
            onPointerCancel={() => {
              setButtonPressed(false)
            }}
            onPointerUp={() => {
              setButtonPressed(false)
              closeBottomSheet()
              onPressAvailability()
            }}
          >
            {availableText}
          </button>
        )
      }
      //안가능하면
      else if (!availability) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonPressed ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonPressed(true)
            }}
            onPointerCancel={() => {
              setButtonPressed(false)
            }}
            onPointerUp={() => {
              setButtonPressed(false)
              closeBottomSheet()
              onPressInavailability()
            }}
          >
            {inavailableText}
          </button>
        )
      }
    }

    return (
      <div className='max-h-[70vh]'>
        {/* 매일 구매 혜택 */}

        <BottomSheetPurchaseQuest
          setFarmData={setFarmData}
          token={token}
          farmData={farmData}
          setResourceType={setResourceType}
          setAddWater={setAddWater}
          setShowPopup={setShowPopup}
        />

        {giveWaterCount === 4 &&
          !farmData?.questLogForGetWaterDailyAttendance && (
            <>
              <div
                style={{
                  backgroundColor: 'black',
                  position: 'absolute',
                  overlay: {
                    background: 'rgba(0,0,0,0)',
                  },
                  width: '100%',
                  height: '100%',
                  bottom: 0,
                  opacity: '0.8',
                  zIndex: 1000,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              />
            </>
          )}

        <BottomSheetHeader historyModalType={'waterHistory'} title='물받기' />

        <div
          className=' pb-[10vw]'
          style={{
            position: 'relative',
            maxHeight: '70vh',
            overflowY: `${isScrollOn ? 'scroll' : 'visible'}`,
          }}
        >
          <div
            style={{
              backgroundColor: '#FEE9E9',
              // margin: 4,
              borderRadius: 8,
            }}
          >
            {((giveWaterCount > 10 &&
              (isCherryPickingUser ||
                (!farmData?.didReceiveNewbiePurchaseWaterReward &&
                  farmData?.isNewUser &&
                  newbiePurchaseWaterRemainTime > 0) ||
                (isSecretIconOn && !secretScratchHistory?.[0]?.didPurchase) ||
                couponInfo?.timeDiff > 0)) ||
              (showSellerCouponPurchaseIconOn && selectedSellerCoupon)) && (
              <img
                style={{
                  width: '11vw',
                  height: '11vw',
                  position: 'absolute',
                  zIndex: 999,
                }}
                src='/waterBottomSheet/leftTopBadge.png'
                alt=''
              />
            )}
            {/* 10분 핫딜 구경하기 */}
            {newUserQuestPrevent &&
              tenMinDealBrowsingQuestStatus !== 'notOpen' &&
              ABv2(farmData?.userId, 'tenMin1110', [95]) === 'a' &&
              AB(farmData?.userId, 'tenMinDealBrowsing1230', [0]) === 'a' &&
              convertCodePushVer(userCodePushVersion) >= 4370 && (
                <div style={{ position: 'relative' }}>
                  <PurchaseMissionComponent
                    config={{
                      thumbnail:
                        '/waterBottomSheet/itemBanner/waterBottomSheetQuest-tenMinDealFireIcon.png',
                      title:
                        tenMinDealBrowsingQuestStatus === 'done'
                          ? `10분 핫딜 구매하기`
                          : `10분 핫딜 구경하기`,
                      reward: '10g',
                      info: '8시부터 10분만 할 수 있어요',
                      button: <TenMinDealBrowsingButton />,
                      skipBorder: false,
                    }}
                  />
                </div>
              )}
            {giveWaterCount > 10 && isCherryPickingUser && (
              <PurchaseMissionComponent
                config={{
                  thumbnail: '/waterBottomSheet/cherryPickIconv2.png',
                  title: '상품 1개 구매하기',
                  reward: '3,000g',
                  info: '*한번만 참여가 가능해요',
                  button: PurchaseMissionComponentButton(
                    isCherryPickPurchaseRewardWaterButtonPressed,
                    setIsCherryPickPurchaseRewardWaterButtonPressed,
                    () => {
                      CherryPickPurchaseRewardWater()
                      closeBottomSheet()
                    },
                  ),
                  skipBorder: true,
                }}
              />
            )}
            {giveWaterCount >= 25 &&
              !farmData?.didReceiveNewbiePurchaseWaterReward &&
              farmData?.isNewUser &&
              newbiePurchaseWaterRemainTime > 0 &&
              convertCodePushVer(userCodePushVersion) >=
                convertCodePushVer('1.6.8') && (
                <PurchaseMissionComponent
                  config={{
                    thumbnail: '/icon/newbiePurchaseRewardv2.png',
                    title: '팜린이 전용 이벤트',
                    reward: '1,000g',
                    // info: "*물 보상이 두배에요",
                    button: PurchaseMissionComponentButton(
                      isNewbiePurchaseButtonClicked,
                      setIsNewbiePurchaseButtonClicked,
                      () => {
                        Log.send({
                          action: 'clickNewbiePurchaseReward',
                          user_id: userId,
                        })
                        const purchaseRewardNewbie = {
                          screen: 'AlfarmPurchaseRewardScreen',
                          rewardType: 'water',
                        }

                        window.location.href = `#newbiePurchaseRewardWater.${JSON.stringify(
                          purchaseRewardNewbie,
                        )}`
                      },
                    ),
                    skipBorder: !isCherryPickingUser,
                  }}
                />
              )}
            {giveWaterCount > 10 && giveWaterCount < 100 && (
              <PurchaseMissionComponent
                config={{
                  thumbnail: '/dailyInvite/inviteFriendsBottomSheet.png',
                  title: '친구 초대',
                  reward: '최대 130g',
                  info: '*친구 초대하고 물 받아요',
                  button: DailyInviteComponentButton(
                    isDailyInviteButtonClicked,
                    setIsDailyInviteButtonClicked,
                    () => {
                      ModalStore.setIsModalOpen('dailyInvite')
                    },
                  ),
                  skipBorder:
                    !isCherryPickingUser &&
                    !(
                      !farmData?.didReceiveNewbiePurchaseWaterReward &&
                      farmData?.isNewUser &&
                      newbiePurchaseWaterRemainTime > 0
                    ) &&
                    !isSecretIconOn,
                }}
              />
            )}
            {giveWaterCount > 10 &&
              isSecretIconOn &&
              !secretScratchHistory?.[0]?.didPurchase && (
                <PurchaseMissionComponent
                  config={{
                    thumbnail: '/secretScratch/entryIconv2.png',
                    title: '행운의 비밀복권',
                    reward: `${commaNumber(
                      secretScratchHistory?.[0]?.prize *
                        secretScratchHistory?.[0]?.multiply,
                    )}g`,
                    // info: "*받은 물의 10배를 받아보세요",
                    button: PurchaseMissionComponentButton(
                      isSecretScratchButtonClicked,
                      setIsSecretScratchButtonClicked,
                      () => {
                        Log.send({
                          action: 'clickSecretScratchReward',
                          user_id: userId,
                        })
                        window.location.href = '#secretScratchReward'
                      },
                    ),
                    skipBorder:
                      !isCherryPickingUser &&
                      !(
                        !farmData?.didReceiveNewbiePurchaseWaterReward &&
                        farmData?.isNewUser &&
                        newbiePurchaseWaterRemainTime > 0
                      ),
                  }}
                />
              )}
            {giveWaterCount > 100 && couponInfo?.timeDiff > 0 && (
              <PurchaseMissionComponent
                config={{
                  thumbnail: '/couponUsageNudge/entryIconv2.png',
                  title: '일석이조 찬스',
                  reward: '500g, 고급비료 1개',
                  // info: "*할인도 받고 보상도 받아요",
                  button: PurchaseMissionComponentButton(
                    isCouponUsageNudgeClicked,
                    setIsCouponUsageNudgeClicked,
                    () => {
                      ModalStore.setIsModalOpen('couponUsageNudge')
                    },
                  ),
                  skipBorder:
                    !isCherryPickingUser &&
                    !(
                      !farmData?.didReceiveNewbiePurchaseWaterReward &&
                      farmData?.isNewUser &&
                      newbiePurchaseWaterRemainTime > 0
                    ) &&
                    !isSecretIconOn,
                }}
              />
            )}
            {giveWaterCount >= 15 &&
              showSellerCouponPurchaseIconOn &&
              selectedSellerCoupon &&
              codePushVersionAvailable(userCodePushVersion, '1.6.66') && (
                <PurchaseMissionComponent
                  config={{
                    thumbnail: '/sellerCouponPurchase/entryIconv2.png',
                    title: '무한쿠폰 쇼핑 찬스',
                    reward: '700g',
                    // info: "*무한으로 즐겨요~ 명륜진사쿠폰",
                    button: PurchaseMissionComponentButton(
                      isSellerCouponNudgeClicked,
                      setIsSellerCouponNudgeClicked,
                      () => {
                        ModalStore.setIsModalOpen('sellerCounponPurchase')
                      },
                    ),
                    skipBorder:
                      !isCherryPickingUser &&
                      !(
                        !farmData?.didReceiveNewbiePurchaseWaterReward &&
                        farmData?.isNewUser &&
                        newbiePurchaseWaterRemainTime > 0
                      ) &&
                      !isSecretIconOn &&
                      !couponInfo?.timeDiff > 0,
                  }}
                />
              )}
          </div>

          <div>
            {/* {cheerupGganbuTime &&
              cheerupGganbuTest === 'a' &&
              canClickFightingGganbuQuest &&
              giveWaterCount > 1000 &&
              gganbuLength > 0 && (
                <QuestComponent
                  iconSrc='/cheerupGganbu/icon.png'
                  title={`맞팜 친구 응원하기`}
                  subtitle='20g, 1번 가능'
                  subtitleIconSrc='/icon/water.png'
                  buttonComponent={<FightingGganbuButton />}
                />
              )} */}
            {giveWaterCount > 100 && !clearedInviteGganbuFirst && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-5.png'
                  alt=''
                />
                <InviteGganbuQuestOne />
              </div>
            )}
            {giveWaterCount > 100 &&
              clearedInviteGganbuFirst &&
              !clearedInviteGganbuSecond && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-9.png'
                    alt=''
                  />
                  <InviteGganbuQuestTwo />
                </div>
              )}
            {giveWaterCount > 100 &&
              clearedInviteGganbuFirst &&
              clearedInviteGganbuSecond &&
              !clearedInviteGganbuThird && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-6.png'
                    alt=''
                  />
                  <InviteGganbuQuestThree />
                </div>
              )}
            {waterBottomSheetInfoTimeChecker?.checkedPhoneBookGganbuAvailable &&
              giveWaterCount > 80 &&
              farmData?.gganbuList?.filter((each) => !each?.deletedAt)?.length <
                5 && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 2,
                      width: '100%',
                      position: 'relative',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-13.png'
                    alt=''
                  />
                  <PhoneBookGganbu />
                </div>
              )}
            {!clearedPurchasedCheckInReward &&
              purchasedCheckInRewardCount < 4 &&
              new Date(farmData?.purchasedCheckInReward?.rewardUntil) >=
                new Date() &&
              farmData?.purchasedCheckInReward?.rewardHistory?.length < 4 && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-16.png'
                    alt=''
                  />
                  <PurchasedCheckInRewardButton />
                </div>
              )}
            {giveWaterCount > 40 &&
              codePushVersionAvailable(userCodePushVersion, '2.1.83') &&
              !farmData?.agreeAppPushAt &&
              (!AlwayzUserStore?.alwayzUserData?.pushNotificationSetting
                ?.receivingPushNotification ||
                !AlwayzUserStore?.alwayzUserData?.pushNotificationSetting
                  ?.receivingAlfarmNotification) && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-15.png'
                    alt=''
                  />
                  <AgreeMarketingPushButton />
                </div>
              )}
            {/* 10분 핫딜 구매 */}
            {DealAutomationStore.isTenMinDealOpen() &&
              giveWaterCount > 50 &&
              codePushVersionAvailable(userCodePushVersion, '4.3.14') &&
              ABv2(farmData?.userId, 'tenMinDealBrowsing1230', [0]) !== 'a' &&
              ABv2(farmData?.userId, 'tenMin1110', [95]) === 'a' && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-tenMinDeal-water.png'
                    alt=''
                  />
                  <TenMinDealPurchaseButton />
                </div>
              )}
            {waterBottomSheetInfoTimeChecker?.attendanceWaterBottomSheetAvailable && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex:
                      giveWaterCount === 4 &&
                      !farmData?.questLogForGetWaterDailyAttendance
                        ? 1002
                        : 2,
                    width: '100%',
                    position: 'relative',
                  }}
                  src='/waterBottomSheet/itemBanner/waterBottomSheetQuest.png'
                  alt=''
                />
                <DailyWaterButton />
                {giveWaterCount === 4 &&
                  !farmData?.questLogForGetWaterDailyAttendance && (
                    <>
                      <div
                        style={{
                          flex: 1,
                          position: 'absolute',
                          left: '10%',
                          top: '22vw',
                          bottom: '0',
                          right: '0',
                          fontSize: '5vw',
                          textAlign: 'center',
                          zIndex: 1004,
                        }}
                        className='bottom-fert-title'
                      >
                        매일 출석하고
                        <div>물을 받을 수 있어요</div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          position: 'absolute',
                          right: '9%',
                          top: '25vw',
                          bottom: '0',
                          zIndex: 1003,
                          fontSize: 18,
                          textAlign: 'center',
                        }}
                        className='slide-top'
                      >
                        <img
                          style={{
                            width: '12vw',
                            transform: 'rotateX( 180deg )',
                          }}
                          src='/icon/guideIndicator.png'
                          alt=''
                        />
                      </div>
                    </>
                  )}
              </div>
            )}
            {(UserStore?.farmData?.giveWaterCount <= 1000 ||
              UserStore?.farmData?.resurrectBonus) &&
              !checkedReviewModal &&
              !clearedReviewQuest && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-8.png'
                    alt=''
                  />
                  <LiveReviewCheck />
                </div>
              )}
            {(UserStore?.farmData?.giveWaterCount <= 1000 ||
              UserStore?.farmData?.resurrectBonus) &&
              checkedReviewModal &&
              !clearedReviewQuest && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-8.png'
                    alt=''
                  />
                  <LiveReviewCheck />
                </div>
              )}
            {/* 광고보고 물받기 or 친구 초대하기 */}
            {newUserQuestPrevent && isInviteWaterMissionAvailable && (
              <>
                <QuestComponent
                  iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-rewardedAd.png'
                  title={`영상 광고 보기`}
                  subtitle='20~50g, 하루 5번 가능'
                  subtitleIconSrc='/icon/water.png'
                  buttonComponent={<RewardedAdButton />}
                  // newPing={true}
                />
              </>
            )}
            {/* 퀴즈 활성화 상태 */}
            {newUserQuestPrevent &&
              canSolveQuiz &&
              solvedQuizCount < AlfarmAdStore.quizAdDailyMaxExposureCount &&
              codePushVersionAvailable(userCodePushVersion, '2.2.18') && (
                <div style={{ position: 'relative' }}>
                  {!farmData?.solvedQuizHistory && (
                    <img
                      style={{
                        zIndex: 1,
                        width: '8%',
                        position: 'absolute',
                        left: '50vw',
                        bottom: '10vw',
                      }}
                      className='wobble-ver-left'
                      src='/icon/newPing.png'
                      alt=''
                    />
                  )}

                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src={
                      AlfarmAdStore.isAlfarmHeavyUserForAd
                        ? ABStore.quizRewardEnhanceTester
                          ? 'https://assets.ilevit.com/7186aec6-183c-43dc-9c14-c327f54eeab7.png' // 3회 최대 50
                          : 'https://assets.ilevit.com/ed6c6073-9bb3-4928-90aa-2089bf2a7a18.png' //3회 15~25
                        : ABStore.quizRewardEnhanceTester &&
                          !UserStore.cherryPickerForAd
                        ? 'https://assets.ilevit.com/6ddc84b6-ca6d-4b27-9409-0c21d61d2afb.png' // 2회 최대 50
                        : 'https://assets.ilevit.com/94d32781-4dd3-4913-b809-78d57b2681cf.png' // 2회 15~25
                    }
                    alt=''
                  />
                  <QuizButton />
                </div>
              )}
            {/* 위너딜 구경하기 - 두 개 있는 이유는 이게 위에 위치이기 때문 (버튼 순서)*/}
            {waterBottomSheetInfoTimeChecker?.winnerDealCount <
              AlfarmAdStore.winnerDealDailyMaxExposureCount &&
              winnerDealTimeIntervalReady &&
              codePushVersionAvailable(userCodePushVersion, '4.3.18') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src={
                      AlfarmAdStore?.isAlfarmHeavyUserForAd
                        ? 'https://assets.ilevit.com/5d68bc95-d2c3-433f-9459-6b3fb6ccb6ef.png' // 3회 최대 50g
                        : !UserStore.cherryPickerForAd
                        ? 'https://assets.ilevit.com/530350fe-948a-424b-b847-7a08bce1a7a2.png' // 2회 최대 50g
                        : 'https://assets.ilevit.com/bc7aff92-9b23-433e-bda9-6005eff6037d.png' //2회 10g
                    }
                    alt=''
                  />
                  <WinnerDealButton
                    farmData={farmData}
                    closeBottomSheet={closeBottomSheet}
                  />
                </div>
              )}
            {/* 일반 상품 구경 */}
            {isBrowsingMissionAvailable &&
              codePushVersionAvailable(userCodePushVersion, '1.6.67') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-2new.png'
                    alt=''
                  />
                  <BrowsingMissionButton />
                </div>
              )}
            {/* 검색 미션 */}
            {(isSearchMissionAvailable || isSearchMissionRewardReceivable) &&
              codePushVersionAvailable(userCodePushVersion, '1.6.71') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-3.png'
                    alt=''
                  />
                  <SearchMissionButton />
                </div>
              )}
            {clearedPurchaseRewardCount < 2 &&
              convertCodePushVer(userCodePushVersion) >=
                convertCodePushVer('1.6.28') &&
              dailyWaterPurchaseRewardToggle && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-4.png'
                    alt=''
                  />
                  <DailyPurchaseRewardWaterButton />
                </div>
              )}
            {isPossibleBrowsingTimeDeal && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src={
                    '/waterBottomSheet/itemBanner/waterBottomSheetQuest-1new.png'
                  }
                  alt=''
                />
                <TimeDealScreenForWaterButton />
              </div>
            )}
            {/* 브랜드딜 구경하기 */}
            {(firstBrandDealOngoing || secondBrandDealOngoing) &&
              waterBottomSheetInfoTimeChecker?.brandDealBrowsingQuestAvailable &&
              codePushVersionAvailable(userCodePushVersion, '2.3.8') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src={
                      '/waterBottomSheet/itemBanner/waterBottomSheetQuest-18new.png'
                    }
                    alt=''
                  />
                  <BrowsingBrandDealQuest />
                </div>
              )}
            {weeklyPurchaseCount <
              dayjs()
                .utcOffset(0)
                .set('hour', 15)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
                .day(+6)
                .toDate() && (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    zIndex: 101,
                    positoin: 'absolute',
                    width: '100%',
                  }}
                >
                  <button>
                    <div
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setInfoContent(`올팜에서 구매한 내역만 포함돼요
                        일주일에 최대 1회 받을 수 있어요           
                        매주 일요일 자정에 초기화돼요`)
                        setShowInfoIcon(true)
                        setTimeout(() => {
                          setShowInfoIcon(false)
                        }, 5000)
                      }}
                    >
                      <img
                        style={{
                          width: '5vw',
                          position: 'absolute',
                          marginTop: '3vw',
                          marginLeft: '8.5vw',
                          right: '35vw',
                        }}
                        src='/icon/fertInformation.png'
                        alt=''
                      />
                    </div>

                    <div>
                      <img
                        style={{
                          zIndex: 101,
                          width: '100%',
                        }}
                        src={
                          '/waterBottomSheet/itemBanner/waterBottomSheetQuest-10.png'
                        }
                        alt=''
                      />
                    </div>
                  </button>
                  <WeeklyPurchaseRewardWaterButtonNew />
                </div>
              </div>
            )}
            {/* 15초 구경하기 퀘스트 */}
            {newUserQuestPrevent &&
              !timeChecker(farmData?.lastWaterBrowsingAdQuestAt) &&
              codePushVersionAvailable(userCodePushVersion, '2.4.19') &&
              isPurchaseUser(farmData) && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-20.png'
                    alt=''
                  />
                  <ButtonComponent
                    availableText={'구경하기'}
                    inavailableText={'구매하기'}
                    availability={false}
                    onPressAvailability={() => {
                      adItemBrowsingQuest(true)
                    }}
                    onPressInavailability={() => {
                      adItemBrowsingQuest(false)
                    }}
                  />
                </div>
              )}
            {newUserQuestPrevent &&
              timeChecker(farmData?.lastWaterBrowsingAdQuestAt) &&
              codePushVersionAvailable(userCodePushVersion, '2.4.19') &&
              isPurchaseUser(farmData) && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-19new.png'
                    alt=''
                  />
                  <ButtonComponent
                    availableText={'구경하기'}
                    inavailableText={'구매하기'}
                    availability={true}
                    onPressAvailability={() => {
                      adItemBrowsingQuest(true)
                    }}
                    onPressInavailability={() => {
                      adItemBrowsingQuest(false)
                    }}
                  />
                </div>
              )}
            {/* 숏드라마 미션 */}
            {codePushVersionAvailable(userCodePushVersion, '6.5.18') &&
              showDramaWaterMission === 'b' &&
              newUserQuestPrevent &&
              !clearedDramaMissionOne && (
                <QuestComponent
                  iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-drama.png'
                  title={`숏드라마 1화 보기 (${Math.min(
                    dramaData?.dramaWatchLog?.length || 0,
                    1,
                  )}/1)`}
                  subtitle='10g, 하루 1번 가능'
                  subtitleIconSrc='/icon/water.png'
                  buttonComponent={
                    <DramaMission missionType='missionOne' missionCount={1} />
                  }
                  infoContent={`- 영상 한 편을 끝까지 봐야만 시청한 것으로 인정돼요
                  - 본 미션은 예고없이 종료될 수 있어요`}
                />
              )}
            {/* 올툰 미션 */}
            {newUserQuestPrevent && !clearedAltoonMissionOne && (
              <QuestComponent
                iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-altoon.png'
                title={`올툰 만화 1화 읽기 (${Math.min(
                  altoonData?.altoonReadLog?.length || 0,
                  1,
                )}/1)`}
                subtitle='10g, 최대 1번 가능'
                subtitleIconSrc='/icon/water.png'
                buttonComponent={
                  <AltoonMission missionType='missionOne' missionCount={1} />
                }
                infoContent={`- 만화 한 회차를 맨 아래까지 봐야 읽은 것으로 인정돼요
                - 본 미션은 예고없이 종료될 수 있어요`}
              />
            )}
            {newUserQuestPrevent &&
              altoonData?.altoonReadLog?.length >= 1 &&
              !clearedAltoonMissionTwo &&
              clearedAltoonMissionOne && (
                <QuestComponent
                  iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-altoon.png'
                  title={`올툰 만화 2화 읽기 (${Math.min(
                    altoonData?.altoonReadLog?.length || 0,
                    2,
                  )}/2)`}
                  subtitle='10g, 최대 1번 가능'
                  subtitleIconSrc='/icon/water.png'
                  buttonComponent={
                    <AltoonMission missionType='missionTwo' missionCount={2} />
                  }
                  infoContent={`- 만화 한 회차를 맨 아래까지 봐야 읽은 것으로 인정돼요
                  - 본 미션은 예고없이 종료될 수 있어요`}
                />
              )}
            {newUserQuestPrevent &&
              altoonData?.altoonReadLog?.length >= 2 &&
              !clearedAltoonMissionFive && (
                <QuestComponent
                  iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-altoon.png'
                  title={`올툰 만화 5화 읽기 (${Math.min(
                    altoonData?.altoonReadLog?.length || 0,
                    5,
                  )}/5)`}
                  subtitle='30g, 최대 1번 가능'
                  subtitleIconSrc='/icon/water.png'
                  buttonComponent={
                    <AltoonMission missionType='missionFive' missionCount={5} />
                  }
                  infoContent={`- 만화 한 회차를 맨 아래까지 봐야 읽은 것으로 인정돼요
                  - 본 미션은 예고없이 종료될 수 있어요`}
                />
              )}
            {/* 물마시기 */}
            {(farmData?.drinkWaterQuest?.length < 8 ||
              !farmData?.drinkWaterQuest) && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-12.png'
                  alt=''
                />
                <DrinkWater />
              </div>
            )}
            {
              // isCodePushVersionLoaded &&
              codePushVersionAvailable(userCodePushVersion, '2.1.55') &&
                dailyWaterPurchaseRewardToggle &&
                photoReviewItemsInfo?.filter(
                  (e) =>
                    e?.isReviewed === false ||
                    (e?.isReviewed === true && e?.isRewarded === false),
                ).length > 0 && (
                  <div style={{ position: 'relative' }}>
                    <img
                      style={{
                        zIndex: 101,
                        width: '100%',
                      }}
                      src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-14.png'
                      alt=''
                    />
                    <PhotoReviewButton />
                  </div>
                )
            }
            {/* 바텀싯 컨트롤... 똥 코드 맞아.. */}
            {/* 폰북 깐부 */}
            {!waterBottomSheetInfoTimeChecker?.checkedPhoneBookGganbuAvailable &&
              giveWaterCount > 80 &&
              farmData?.gganbuList?.filter((each) => !each?.deletedAt)?.length <
                5 && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 2,
                      width: '100%',
                      position: 'relative',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-13.png'
                    alt=''
                  />
                  <PhoneBookGganbu />
                </div>
              )}
            {/* 출첵 완료 매대 */}
            {clearedPurchasedCheckInReward &&
              purchasedCheckInRewardCount < 4 &&
              new Date(farmData?.purchasedCheckInReward?.rewardUntil) >=
                new Date() &&
              farmData?.purchasedCheckInReward?.rewardHistory?.length < 4 && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-16.png'
                    alt=''
                  />
                  <PurchasedCheckInRewardButton />
                </div>
              )}
            {/* 데일리 출석 */}
            {!waterBottomSheetInfoTimeChecker?.attendanceWaterBottomSheetAvailable && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 2,
                    width: '100%',
                    position: 'relative',
                  }}
                  src='/waterBottomSheet/itemBanner/waterBottomSheetQuest.png'
                  alt=''
                />
                <DailyWaterButton />
              </div>
            )}
            {/* 광고보기 완료 or 친구 초대 */}
            {newUserQuestPrevent && !isInviteWaterMissionAvailable && (
              <>
                <QuestComponent
                  iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-rewardedAd.png'
                  title={`영상 광고 보기`}
                  subtitle='20~50g, 하루 5번 가능'
                  subtitleIconSrc='/icon/water.png'
                  buttonComponent={<RewardedAdButton />}
                  // newPing={true}
                />
              </>
            )}
            {/* 퀴즈 비활성화 상태 */}
            {(!canSolveQuiz ||
              solvedQuizCount >= AlfarmAdStore.quizAdDailyMaxExposureCount) &&
              codePushVersionAvailable(userCodePushVersion, '2.2.18') && (
                <div style={{ position: 'relative' }}>
                  {!farmData?.solvedQuizHistory && (
                    <img
                      style={{
                        zIndex: 1,
                        width: '8%',
                        position: 'absolute',
                        left: '50vw',
                        bottom: '10vw',
                      }}
                      className='wobble-ver-left'
                      src='/icon/newPing.png'
                      alt=''
                    />
                  )}

                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src={
                      AlfarmAdStore.isAlfarmHeavyUserForAd
                        ? ABStore.quizRewardEnhanceTester
                          ? 'https://assets.ilevit.com/7186aec6-183c-43dc-9c14-c327f54eeab7.png' // 3회 최대 50
                          : 'https://assets.ilevit.com/ed6c6073-9bb3-4928-90aa-2089bf2a7a18.png' //3회 15~25
                        : ABStore.quizRewardEnhanceTester &&
                          !UserStore.cherryPickerForAd
                        ? 'https://assets.ilevit.com/6ddc84b6-ca6d-4b27-9409-0c21d61d2afb.png' // 2회 최대 50
                        : 'https://assets.ilevit.com/94d32781-4dd3-4913-b809-78d57b2681cf.png' // 2회 15~25
                    }
                    alt=''
                  />
                  <QuizButton />
                </div>
              )}
            {/* 위너딜 구경하기 - 퀘스트 불가능할 때 */}
            {(waterBottomSheetInfoTimeChecker?.winnerDealCount >=
              AlfarmAdStore.winnerDealDailyMaxExposureCount ||
              !winnerDealTimeIntervalReady) &&
              codePushVersionAvailable(userCodePushVersion, '4.3.18') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src={
                      AlfarmAdStore?.isAlfarmHeavyUserForAd
                        ? 'https://assets.ilevit.com/5d68bc95-d2c3-433f-9459-6b3fb6ccb6ef.png' // 3회 최대 50g
                        : !UserStore.cherryPickerForAd
                        ? 'https://assets.ilevit.com/530350fe-948a-424b-b847-7a08bce1a7a2.png' // 2회 최대 50g
                        : 'https://assets.ilevit.com/bc7aff92-9b23-433e-bda9-6005eff6037d.png' //2회 10g
                    }
                    alt=''
                  />
                  <WinnerDealButton
                    farmData={farmData}
                    closeBottomSheet={closeBottomSheet}
                  />
                </div>
              )}
            {/* 30초 상품 구경하기 완료 */}
            {!isBrowsingMissionAvailable &&
              codePushVersionAvailable(userCodePushVersion, '1.6.67') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-2new.png'
                    alt=''
                  />
                  <BrowsingMissionButton />
                </div>
              )}
            {/* 검색 미션 */}
            {!isSearchMissionAvailable &&
              !isSearchMissionRewardReceivable &&
              codePushVersionAvailable(userCodePushVersion, '1.6.71') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-3.png'
                    alt=''
                  />
                  <SearchMissionButton />
                </div>
              )}
            {/* 상품 1개 구매하기 */}
            {clearedPurchaseRewardCount >= 2 &&
              convertCodePushVer(userCodePushVersion) >=
                convertCodePushVer('1.6.28') &&
              dailyWaterPurchaseRewardToggle && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-4.png'
                    alt=''
                  />
                  <DailyPurchaseRewardWaterButton />
                </div>
              )}
            {/* 타임딜 */}
            {!isPossibleBrowsingTimeDeal && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src={
                    '/waterBottomSheet/itemBanner/waterBottomSheetQuest-1new.png'
                  }
                  alt=''
                />
                <TimeDealScreenForWaterButton />
              </div>
            )}
            {/* 브랜드딜 구경하기 */}
            {(firstBrandDealOngoing || secondBrandDealOngoing) &&
              !waterBottomSheetInfoTimeChecker?.brandDealBrowsingQuestAvailable &&
              codePushVersionAvailable(userCodePushVersion, '2.3.8') && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src={
                      '/waterBottomSheet/itemBanner/waterBottomSheetQuest-18new.png'
                    }
                    alt=''
                  />
                  <BrowsingBrandDealQuest />
                </div>
              )}
            {weeklyPurchaseCount > 2 &&
              lastClearedWeeklyPurchaseRewardTime <
                dayjs()
                  .utcOffset(0)
                  .set('hour', 15)
                  .set('minute', 0)
                  .set('second', 0)
                  .set('millisecond', 0)
                  .day(+6)
                  .toDate() &&
              dayjs().startOf('week').toDate() <=
                lastClearedWeeklyPurchaseRewardTime && (
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      zIndex: 101,
                      positoin: 'absolute',
                      width: '100%',
                    }}
                  >
                    <button>
                      <div
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          setShowInfoIcon(true)
                          setTimeout(() => {
                            setShowInfoIcon(false)
                          }, 5000)
                        }}
                      >
                        <img
                          style={{
                            width: '5vw',
                            position: 'absolute',
                            marginTop: '3vw',
                            marginLeft: '8.5vw',
                          }}
                          src='/icon/fertInformation.png'
                          alt=''
                        />
                      </div>

                      <div>
                        <img
                          style={{
                            zIndex: 101,
                            width: '100%',
                          }}
                          src={
                            '/waterBottomSheet/itemBanner/waterBottomSheetQuest-10.png'
                          }
                          alt=''
                        />
                      </div>
                    </button>
                    <WeeklyPurchaseRewardWaterButtonNew />
                  </div>
                </div>
              )}
            {/* 물마시기 */}
            {farmData?.drinkWaterQuest?.length >= 8 && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-12.png'
                  alt=''
                />
                <DrinkWater />
              </div>
            )}
            {!(
              UserStore?.farmData?.giveWaterCount <= 1000 ||
              UserStore?.farmData?.resurrectBonus
            ) &&
              !checkedReviewModal &&
              !clearedReviewQuest && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-8.png'
                    alt=''
                  />
                  <LiveReviewCheck />
                </div>
              )}
            {!(
              UserStore?.farmData?.giveWaterCount <= 1000 ||
              UserStore?.farmData?.resurrectBonus
            ) &&
              checkedReviewModal &&
              !clearedReviewQuest && (
                <div style={{ position: 'relative' }}>
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-8.png'
                    alt=''
                  />
                  <LiveReviewCheck />
                </div>
              )}
            {clearedReviewQuest && (
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-8.png'
                  alt=''
                />
                <LiveReviewCheck />
              </div>
            )}
            <div id='waterBottomSheetEnd' />
            <div style={{ height: '22px' }} />
          </div>
          {scrollIndicator && (
            <div
              className='bounce-top'
              style={{
                width: 35,
                height: 35,
                // backgroundColor: "red",
                position: 'fixed',
                bottom: 36,
                left: '50%',
                // transform: "translate(-50%)",
                backgroundImage: 'url(../waterBottomSheet/scrollIndicator.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          )}
          {showInfoIcon && infoContent && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: '7.5%',
                top: '50vw',
                zIndex: 2000,
                width: '85%',
                background: 'rgba(0,0,0,0.8)',
                borderRadius: '4vw',
                fontFamily: 'maplestory',
                fontSize: '5vw',
                textAlign: 'center',
                paddingTop: '8vw',
                paddingBottom: '8vw',
                paddingRight: '4vw',
                paddingLeft: '4vw',
                lineHeight: '150%',
              }}
            >
              <ImageText
                className='font-thin'
                fontSize={5}
                style={{
                  color: '#8E8D9B',
                }}
                text={infoContent}
              />
            </div>
          )}
        </div>
      </div>
    )
  },
)
export default GetWaterButtonBs
