import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import ModalFrame from './ModalFrame'
import ModalStore from 'store/ModalStore'
import { useNavigate } from 'react-router-dom'
import WhiteTextTimer from 'comps/WhiteTextTimer'

const NewYearDealModal = observer(
  ({ isFrom, itemType, token, randomString }) => {
    const navigate = useNavigate()
    return (
      <>
        <ModalFrame
          closeButtonStyle={styles.closeButton}
          modalContentStyle={styles.modalContent}
        >
          <div
            style={{
              paddingLeft: '4vw',
              color: '#6A3615',

              fontSize: '4vw',
              marginTop: '0vw',
            }}
          >
            추석 특가전 이벤트는 <br />
            9월 23일까지 진행돼요.
          </div>
          {/* <img
            src='../dealEvent/chuseokDeal24/coin.png'
            alt='reward'
            style={{ width: '28%', marginLeft: '36.5%', marginTop: '4vw' }}
          /> */}
          <div
            style={{
              color: '#6A3615',
              paddingLeft: '4vw',
              fontSize: '4vw',
              marginTop: '6vw',
            }}
          >
            9월 24일부터 새로운 특가 이벤트가 <br />
            시작돼요. <br />
            <br />
            사용하지 않은 송편은 새로운 특가 이벤트에서 이어서 사용할 수 있어요.
          </div>
          <div
            style={{
              padding: '2vw',
              background: '#FFC634',
              width: '45vw',
              borderRadius: '8vw',
              color: '#6A3615',
              boxShadow:
                '0px 2px 0px 0px #FFA234, 0px -1px 0px 0px #FFD874 inset',
              height: '11vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4vw',
              marginLeft: '15%',
              marginTop: '4vw',
            }}
            onClick={() => {
              ModalStore?.setIsModalOpen('basic')
            }}
          >
            확인
          </div>
        </ModalFrame>
      </>
    )
  },
)

const styles = {
  closeButton: {
    position: 'absolute',
    top: '-18vw',
    right: '0vw',
    width: '8vw',
  },
  modalContent: {
    width: '70vw',
    height: '80vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    background: '#F9F0DE',
    border: '2vw solid #946134',
    borderRadius: '5vw',
  },
}

export default NewYearDealModal
